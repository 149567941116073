import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { CenteredDiv } from '@wavetronix/common-components'
import { useEffect, useState } from 'react'

const isSelected = (documentProductTypes, id) => {
  if (!documentProductTypes) {
    return false
  }
  var selected = documentProductTypes
    .map(productType => productType.id === id)
    .reduce((previousValue, currentValue) => previousValue || currentValue, false)
  return selected
}

export default function ProductTypeSelector({ selectorStyle, documentProductTypes, productTypes, onChange, docId }) {
  const [selectedMap, setSelectedMap] = useState({})
  const [prevDocId, setPrevDocId] = useState(docId)

  useEffect(() => {
    if (
      docId !== prevDocId ||
      Object.keys(selectedMap).length !== productTypes.length ||
      productTypes.some(p => !(p.name in selectedMap))
    ) {
      let map = {}
      for (let type of productTypes) {
        map[type.name] = isSelected(documentProductTypes, type.id)
      }
      setSelectedMap(map)
      setPrevDocId(docId)
    }
  }, [productTypes, documentProductTypes, selectedMap, docId, prevDocId])

  function handleOnClick(productType) {
    let map = {}

    for (let value in selectedMap) {
      if (value === productType.name) {
        map[value] = !selectedMap[value]
      } else {
        map[value] = selectedMap[value]
      }
    }

    setSelectedMap(map)

    let selected = productTypes
      .filter(p => map[p.name])
      .map(p => {
        let newSelected = { ...p }
        delete newSelected.isSelected
        return newSelected
      })
    onChange(selected)
  }

  if (!selectedMap) {
    return <CenteredDiv></CenteredDiv>
  }

  return (
    <div style={selectorStyle}>
      <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
        <div style={{ height: 200, width: '100%', overflow: 'auto', paddingRight: '10px', paddingLeft: '8px' }}>
          <List>
            {productTypes.map(productType => {
              return (
                <ListItem key={productType.id} disablePadding>
                  <ListItemButton
                    id={`${productType.id}ListItemButton`}
                    role={undefined}
                    onClick={e => handleOnClick(productType)}
                    dense
                  >
                    <ListItemIcon>
                      <Checkbox
                        id={`${productType.id}Checkbox`}
                        edge='start'
                        checked={selectedMap[productType.name] ? selectedMap[productType.name] : false}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText id={productType.id} primary={productType.name} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        </div>
      </div>
    </div>
  )
}
