import * as React from 'react'
import { CenteredDiv, PrimaryButton, RegularButton, SnackbarVariants, SlideUpDialog } from '@wavetronix/common-components'
import DialogContent from '@mui/material/DialogContent'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Switch } from '@mui/material'
import GatekeeperApi from '../../api/GatekeeperApi'

const classes = {
  fileMetadata: {
    width: '80%',
    marginTop: '15px'
  }
}

export default function EditRoleModal({ open, onClose, refetch, role }) {
  const { instance, accounts } = useMsal()
  const [description, setDescription] = useState('')
  const [name, setName] = useState('')
  const [isInternal, setIsInternal] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [isUploading, setIsUploading] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))

  useEffect(() => {
    if (role) {
      setDescription(role.description ? role.description : '')
      setName(role.id ? role.id : '')
      setIsInternal(role.isInternal ? role.isInternal : false)
    }
  }, [role])

  function closeModal() {
    refetch()
    onClose()
    setDescription('')
    setName('')
  }

  function createRole() {
    const newRole = {
      id: role.id,
      description: description,
      isInternal: isInternal
    }
    return newRole
  }

  const updateRole = async role => {
    await GatekeeperApi.updateRoleInfo(instance, accounts, role).then(
      _ => {
        enqueueSnackbar('Update role success', SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`Failed to update role`, SnackbarVariants.ERROR)
      }
    )
  }

  return (
    <SlideUpDialog
      id='editRoleModal'
      maxWidth={'sm'}
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      title={<h3 style={{ margin: 0 }}>Edit Role</h3>}
      actions={
        <>
          <RegularButton id='editRoleCancelButton' onClick={onClose} disabled={isUploading}>
            &nbsp;Cancel
          </RegularButton>
          <PrimaryButton
            id='editRoleCreateButton'
            style={{ marginRight: 15 }}
            onClick={async () => {
              setIsUploading(true)
              await updateRole(createRole())
              setIsUploading(false)
              closeModal()
            }}
          >
            &nbsp;Update
          </PrimaryButton>
        </>
      }
    >
      <DialogContent>
        <CenteredDiv>
          <TextField
            id='roleNameTextField'
            disabled
            label={'Role Name'}
            variant='outlined'
            style={classes.fileMetadata}
            value={name}
          />
        </CenteredDiv>
        <CenteredDiv>
          <TextField
            id='roleDescriptionTextField'
            disabled={isUploading}
            label={'Description'}
            value={description}
            variant='outlined'
            sx={classes.fileMetadata}
            multiline
            minRows={4}
            onChange={e => setDescription(e.target.value)}
          />
        </CenteredDiv>
        <CenteredDiv>
          <div style={classes.fileMetadata}>
            <Switch
              id='roleInternalSwitch'
              checked={isInternal}
              color='primary'
              onChange={e => setIsInternal(e.target.checked)}
            />
            Internal Role Only
          </div>
        </CenteredDiv>
      </DialogContent>
    </SlideUpDialog>
  )
}
