import { Tab, Tabs } from '@mui/material'
import { useNavigate } from 'react-router'
import { WtxColors, checkHasAccess } from '@wavetronix/common-components'
import { env } from '../index.js'
import { useMemo } from 'react'
const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    alignItems: 'end',
    padding: '0px 16px 0px 0px',
    color: 'black'
  },
  tabParent: {
    width: '186px'
  }
}

export const TAB_OPTIONS = [
  {
    roles: ['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member', 'Dealer'],
    id: 'groupsTab',
    label: 'Nexus Groups',
    value: 'groups'
  },
  {
    roles: ['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member'],
    id: 'usersTab',
    label: 'Users',
    value: 'users'
  },
  {
    roles: ['Gatekeeper Admin'],
    id: 'rolesTab',
    label: 'Roles',
    value: 'roles'
  }
]

export default function PageNavbar(props) {
  const navigate = useNavigate()

  const onChange = path => {
    if (window.location.pathname !== `/${path}`) {
      navigate(`/${path}`)
    }
    props.onChangeCallback(path)
  }

  const userTabOptions = useMemo(() => {
    return TAB_OPTIONS.filter(option => {
      return checkHasAccess(option.roles, props.accounts, env)
    })
  }, [props.accounts])

  return (
    <Tabs
      value={props.value}
      TabIndicatorProps={{
        sx: {
          backgroundColor: WtxColors.IQ_BLUE
        }
      }}
      orientation='vertical'
      sx={classes.tabParent}
    >
      {userTabOptions.map(option => {
        return (
          <Tab
            key={option.id}
            id={option.id}
            value={option.value}
            sx={classes.tab}
            label={option.label}
            onClick={() => onChange(option.value)}
          />
        )
      })}
    </Tabs>
  )
}
