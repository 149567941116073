import { getResourceUrl, useWtxLocalization } from '@wavetronix/common-components'
import { React, useState } from 'react'

const classes = {
  buttonBase: {
    display: 'block',
    width: '100%',
    height: '100%',
    color: 'transparent'
  },
  imageBase: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 'auto'
  },
  titleText: {
    fontFamily: 'Proxima Nova',
    fontWeight: 'bold',
    fontSize: 18,
    display: 'inline',
    verticalAlign: 'middle',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    color: 'white'
  },
  titleIcon: {
    width: '50%',
    height: '50%'
  },
  appCard: {
    borderRadius: '20px',
    boxShadow: '3px 0px 53px -20px rgba(0,0,0,0.6)',
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    height: '100%'
  }
}

export default function AppCard({ app, env }) {
  const [redirect, SetRedirect] = useState(true)
  let localizedStrings = useWtxLocalization()

  const getAppName = app => {
    let appName = app
    if (Object.keys(localizedStrings).includes(app)) {
      appName = localizedStrings[app]
    }
    return appName
  }

  return (
    <div
      id={`${app.id}CardLink`}
      style={classes.appCard}
      onMouseDown={_ => {
        SetRedirect(true)
      }}
      onMouseUp={_ => {
        if (redirect === true) {
          return (window.location.href = app.url)
        }
      }}
      onMouseMove={_ => {
        SetRedirect(false)
      }}
      onTouchStart={_ => {
        SetRedirect(true)
      }}
      onTouchEnd={_ => {
        if (redirect === true) {
          return (window.location.href = app.url)
        }
      }}
      onTouchMove={_ => {
        SetRedirect(false)
      }}
    >
      <div style={{ height: '80%', ...classes.imageBase }}>
        <img alt={app.appName} style={classes.titleIcon} src={`${getResourceUrl(env.runMode)}/images/apps/${app.icon}`} />
      </div>
      <div
        style={{
          backgroundColor: app.color,
          height: '20%',
          borderRadius: '0px 0px 20px 20px',
          ...classes.imageBase
        }}
      >
        <div style={classes.titleText}>{getAppName(app.appName)}</div>
      </div>
    </div>
  )
}
