import { Box, Snackbar, SnackbarContent } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { Routes, Route, Outlet } from 'react-router-dom'
import { useState, useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { useSnackbar } from 'notistack'
import { SnackbarVariants, checkHasAccess, getMe, SuccessButton, DeleteButton } from '@wavetronix/common-components'
import { useNavigate } from 'react-router'

import GatekeeperApi from '../../../api/GatekeeperApi.js'
import EditGroupOverview from './EditGroupOverview'
import EditGroupPermissions from './EditGroupPermissions'
import EditGroupUsers from './EditGroupUsers'
import EditGroupUpdates from './EditGroupUpdates'
import EditGroupDocuments from './EditGroupDocuments'
import EditGroupPackages from './EditGroupPackages'
import EditGroupNavbar from './EditGroupNavbar'
import { DEFAULT_GROUP } from '../../modals/AddGroupModal'
import { useCrossTabState, clearState } from '../../../customhooks/useCrossTabState'
import { env } from '../../../index.js'
import { listHaveDifferences, getRemovedValues, getAddedValues } from '../../../utils/arrayUtils.js'

const sendChangeRequest = {
  useradmins: async (instance, accounts, groupId, groupUpdateInfo) =>
    await GatekeeperApi.changeGroupByType(instance, accounts, 'useradmins', groupId, groupUpdateInfo),
  updates: async (instance, accounts, groupId, groupUpdateInfo) =>
    await GatekeeperApi.changeGroupByType(instance, accounts, 'updates', groupId, groupUpdateInfo),
  documents: async (instance, accounts, groupId, groupUpdateInfo) =>
    await GatekeeperApi.changeGroupByType(instance, accounts, 'documents', groupId, groupUpdateInfo),
  packages: async (instance, accounts, groupId, groupUpdateInfo) =>
    await GatekeeperApi.changeGroupByType(instance, accounts, 'packages', groupId, groupUpdateInfo),
  users: async (instance, accounts, groupId, groupUpdateInfo) =>
    await GatekeeperApi.changeGroupByType(instance, accounts, 'users', groupId, groupUpdateInfo),
  '': () => {
    console.log('invalid change request type')
  }
}

export default function GroupPageWrapper() {
  const location = useLocation()
  const navigate = useNavigate()

  const { enqueueSnackbar } = useSnackbar()
  const { instance, accounts } = useMsal()
  const [groupState, setGroupState] = useCrossTabState(`editGroupState`, 'overview')
  const [group, setGroup] = useCrossTabState(`editGroup`, DEFAULT_GROUP)
  const [isUploading, setIsUploading] = useState(false)
  const [showAlert, setShowAlert] = useState({ display: false, requestedPath: '' })

  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search)

  // Get individual query parameters
  const groupId = queryParams.get('id') || ''
  const isDealerGroup = queryParams.get('isDealerGroup') === 'true' || false

  const { data: groupData, refetch: groupRefetch } = useQuery({
    queryKey: [`groupData${groupId}`, groupId],
    queryFn: async () => await GatekeeperApi.getGroup(instance, accounts, groupId),
    enabled: !!groupId && groupId !== ''
  })

  const { data: myUser } = useQuery({
    queryKey: ['myUser'],
    queryFn: async () => await getMe(instance, env, accounts)
  })

  const {
    data: usersData,
    isLoading: usersIsLoading,
    refetch: usersRefetch
  } = useQuery({
    queryKey: ['injectedUsers'],
    queryFn: async () => {
      return await GatekeeperApi.getUsersInjected(instance, accounts)
    }
  })

  const isReadOnly = useMemo(() => {
    return !checkHasAccess(group.permissableAdmins, accounts, env)
  }, [accounts, group])

  const usersMap = useMemo(() => {
    if (usersData) {
      let users = usersData.reduce((map, obj) => {
        map[obj.id] = { ...obj, name: `${obj.givenName} ${obj.surname}`, isCurrentGroupMember: obj.groups.includes(groupId) }
        return map
      }, {})
      return users
    }
  }, [usersData, groupId])

  const [usersHasChanges, setUsersHasChanges] = useState(false)
  const [updatesHasChanges, setUpdatesHasChanges] = useState(false)
  const [documentsHasChanges, setDocumentsHasChanges] = useState(false)
  const [packagesHasChanges, setPackagesHasChanges] = useState(false)
  const groupHasChanges = useMemo(() => {
    let changes = {
      overview: {
        nameHasChanges: false,
        descriptionHasChanges: false,
        statesHasChanges: false
      },
      permissions: {
        rolesHasChanges: false,
        docAccessHasChanges: false,
        updatesAccessHasChanges: false,
        roleAdminHasChanges: false,
        userAdminHasChanges: false
      }
    }
    if (group && groupData) {
      changes.overview.nameHasChanges = group.groupName !== groupData.groupName
      changes.overview.descriptionHasChanges = group.groupDescription !== groupData.groupDescription
      changes.overview.statesHasChanges = listHaveDifferences(group.dealerStates, groupData.dealerStates)

      changes.permissions.rolesHasChanges = listHaveDifferences(group.roles, groupData.roles)
      changes.permissions.docAccessHasChanges =
        group.documentsAccessLevel[env.basicAuthentication.fromCompany] !==
        groupData.documentsAccessLevel[env.basicAuthentication.fromCompany]
      changes.permissions.updatesAccessHasChanges =
        group.updatesAccessLevel[env.basicAuthentication.fromCompany] !==
        groupData.updatesAccessLevel[env.basicAuthentication.fromCompany]
      changes.permissions.roleAdminHasChanges = listHaveDifferences(group.permissableAdmins, groupData.permissableAdmins)
      changes.permissions.userAdminHasChanges = listHaveDifferences(group.admins, groupData.admins)
    }
    return changes
  }, [group, groupData])

  const generateListDiff = (notes, type, typeName, typeMap, typeAttribute) => {
    let removed = []
    let added = []

    if (typeMap) {
      removed = getRemovedValues(groupData[type], group[type]).map(typeValue => typeMap[typeValue][typeAttribute])
      added = getAddedValues(groupData[type], group[type]).map(typeValue => typeMap[typeValue][typeAttribute])
    } else {
      removed = getRemovedValues(groupData[type], group[type])
      added = getAddedValues(groupData[type], group[type])
    }

    if (removed.length > 0) {
      notes.push(`${typeName} removed: ${removed.join(', ')}`)
    }
    if (added.length > 0) {
      notes.push(`${typeName} added: ${added.join(', ')}`)
    }
  }

  const generatedNotesForGroup = fromPage => {
    let notes = []

    if (fromPage === 'overview') {
      if (groupHasChanges.overview.nameHasChanges) {
        notes.push(`Group Name changed`)
      }
      if (groupHasChanges.overview.descriptionHasChanges) {
        notes.push(`Group Description changed`)
      }
      if (groupHasChanges.overview.statesHasChanges) {
        generateListDiff(notes, 'dealerStates', 'Dealer States')
      }
    } else if (fromPage === 'permissions') {
      if (groupHasChanges.permissions.rolesHasChanges) {
        generateListDiff(notes, 'roles', 'Roles')
      }
      if (groupHasChanges.permissions.docAccessHasChanges) {
        notes.push(`Group Documents Access changed to ${group.documentsAccessLevel[env.basicAuthentication.fromCompany]}`)
      }
      if (groupHasChanges.permissions.updatesAccessHasChanges) {
        let accessLevel = group.updatesAccessLevel[env.basicAuthentication.fromCompany]
        let accessName = accessLevel === 'FullRelease' ? 'QA Release' : accessLevel
        notes.push(`Group Updates Access changed to ${accessName}`)
      }
      if (groupHasChanges.permissions.roleAdminHasChanges) {
        generateListDiff(notes, 'permissableAdmins', 'Role Administrators')
      }
      if (groupHasChanges.permissions.userAdminHasChanges) {
        generateListDiff(notes, 'admins', 'User Administrators', usersMap, 'name')
      }
    }

    return notes
  }

  useEffect(() => {
    if (groupData) {
      setGroup(g => ({ ...g, ...groupData }))
    }
  }, [groupData, setGroup])

  const allGroupChanges = useMemo(() => {
    if (
      usersHasChanges ||
      updatesHasChanges ||
      documentsHasChanges ||
      packagesHasChanges ||
      Object.values(groupHasChanges.overview).includes(true) ||
      Object.values(groupHasChanges.permissions).includes(true)
    ) {
      return true
    } else {
      return false
    }
  }, [usersHasChanges, updatesHasChanges, documentsHasChanges, packagesHasChanges, groupHasChanges])

  //Function is called for group meta data ie. name, description, roles, states, role admins
  const updateGroup = async fromPage => {
    let newGroup = {}
    if (fromPage === 'overview') {
      newGroup = {
        ...groupData,
        groupName: group.groupName,
        groupDescription: group.groupDescription,
        dealerStates: group.dealerStates
      }
    } else if (fromPage === 'permissions') {
      newGroup = {
        ...groupData,
        roles: group.roles,
        admins: group.admins,
        permissableAdmins: group.permissableAdmins,
        documentsAccessLevel: group.documentsAccessLevel,
        updatesAccessLevel: group.updatesAccessLevel
      }
    } else {
      return
    }

    let groupUpdateInfo = {
      group: newGroup,
      notes: generatedNotesForGroup(fromPage).join('\n'),
      ids: [] //should need to specify ids when calling general update endpoint
    }
    await GatekeeperApi.updateGroup(instance, accounts, groupUpdateInfo).then(
      _ => {
        enqueueSnackbar('Updated group success', SnackbarVariants.SUCCESS)
        groupRefetch()
      },
      error => {
        enqueueSnackbar(`Failed to update group`, SnackbarVariants.ERROR)
      }
    )
  }

  //Function is called for users, updates, documents, packages, user admins
  const updateGroupChangeType = async (type, ids, notes) => {
    let groupUpdateInfo = {
      group: group,
      notes: notes.join('\n'),
      ids: ids
    }

    await sendChangeRequest[type](instance, accounts, groupId, groupUpdateInfo).then(
      _ => {
        enqueueSnackbar('Updated group success', SnackbarVariants.SUCCESS)
        groupRefetch()
        if (type === 'users') usersRefetch()
      },
      error => {
        enqueueSnackbar(`Failed to update group`, SnackbarVariants.ERROR)
      }
    )
  }

  const clearGroupInfo = () => {
    clearState(`editGroup`, DEFAULT_GROUP)
    clearState(`editGroupState`, 'overview')
    navigate(`/groups`)
  }

  const navigateToPath = path => {
    const queryParams = new URLSearchParams(location.search)

    if (window.location.pathname !== `/${path}`) {
      const newUrl = `${path}?${queryParams.toString()}`
      navigate(`${newUrl}`)
      setGroupState(path)
    }
  }

  const onNavChange = path => {
    if (!allGroupChanges) {
      if (path === 'groupBackButton') {
        clearGroupInfo()
      } else {
        navigateToPath(path)
      }
    } else {
      setShowAlert({ display: true, requestedPath: path })
    }
  }

  const alertYesAction = () => {
    if (showAlert.requestedPath === 'groupBackButton') {
      clearGroupInfo()
    } else {
      navigateToPath(showAlert.requestedPath)
    }
    setShowAlert({ display: false, requestedPath: '' })
  }

  return (
    <Routes>
      <Route
        element={
          <Box sx={{ display: 'flex' }}>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={showAlert.display}
              onClose={() => setShowAlert({ display: false, requestedPath: '' })}
            >
              <SnackbarContent
                message={
                  <div
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}
                  >
                    <span>
                      Unsaved changes on group. Continue without saving? <br />
                    </span>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        marginTop: '10px'
                      }}
                    >
                      <SuccessButton onClick={alertYesAction}>YES</SuccessButton>
                      <DeleteButton onClick={() => setShowAlert({ display: false, requestedPath: '' })}>NO</DeleteButton>
                    </Box>
                  </div>
                }
              />
            </Snackbar>
            <Box>
              <EditGroupNavbar value={groupState} accounts={accounts} isDealerGroup={isDealerGroup} onNavChange={onNavChange} />
            </Box>
            <Box sx={{ margin: '10px', width: '100%' }}>
              <Outlet />
            </Box>
          </Box>
        }
      >
        <Route
          path='overview'
          element={
            <EditGroupOverview
              isDealerGroup={isDealerGroup}
              group={group}
              setGroup={setGroup}
              isReadOnly={isReadOnly}
              accounts={accounts}
              instance={instance}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              groupHasChanges={groupHasChanges.overview}
              updateGroup={updateGroup}
            />
          }
        />
        <Route
          path='permissions'
          element={
            <EditGroupPermissions
              isDealerGroup={isDealerGroup}
              group={group}
              setGroup={setGroup}
              updateGroup={updateGroup}
              instance={instance}
              accounts={accounts}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              usersData={usersData}
              usersMap={usersMap ? usersMap : {}}
              isReadOnly={isReadOnly}
              groupHasChanges={groupHasChanges.permissions}
              updateGroupChangeType={updateGroupChangeType}
              generateListDiff={generateListDiff}
            />
          }
        />
        <Route
          path='users'
          element={
            <EditGroupUsers
              isDealerGroup={isDealerGroup}
              group={group}
              setGroup={setGroup}
              instance={instance}
              accounts={accounts}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              usersData={usersData}
              usersIsLoading={usersIsLoading}
              usersMap={usersMap ? usersMap : {}}
              isReadOnly={isReadOnly}
              updateGroupChangeType={updateGroupChangeType}
              myUser={myUser}
              hasChanges={usersHasChanges}
              setHasChanges={setUsersHasChanges}
            />
          }
        />
        <Route
          path='updates'
          element={
            <EditGroupUpdates
              groupData={groupData}
              group={group}
              setGroup={setGroup}
              instance={instance}
              accounts={accounts}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              isReadOnly={isReadOnly}
              updateGroupChangeType={updateGroupChangeType}
              generateListDiff={generateListDiff}
              hasChanges={updatesHasChanges}
              setHasChanges={setUpdatesHasChanges}
            />
          }
        />
        <Route
          path='documents'
          element={
            <EditGroupDocuments
              groupData={groupData}
              group={group}
              setGroup={setGroup}
              instance={instance}
              accounts={accounts}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              isReadOnly={isReadOnly}
              updateGroupChangeType={updateGroupChangeType}
              generateListDiff={generateListDiff}
              hasChanges={documentsHasChanges}
              setHasChanges={setDocumentsHasChanges}
            />
          }
        />
        <Route
          path='packages'
          element={
            <EditGroupPackages
              groupData={groupData}
              group={group}
              setGroup={setGroup}
              instance={instance}
              accounts={accounts}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              isReadOnly={isReadOnly}
              updateGroupChangeType={updateGroupChangeType}
              generateListDiff={generateListDiff}
              hasChanges={packagesHasChanges}
              setHasChanges={setPackagesHasChanges}
            />
          }
        />
      </Route>
    </Routes>
  )
}
