import { useMsal } from '@azure/msal-react'
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Tab,
  Tabs,
  TextField,
  CardHeader,
  Box,
  Chip
} from '@mui/material'
import {
  CenteredDiv,
  CustomAccordion,
  DeleteButton,
  HasAccess,
  SlideUpDialog,
  SnackbarVariants,
  SuccessButton,
  useWtxLocalization,
  WtxColors
} from '@wavetronix/common-components'
import { useSnackbar, closeSnackbar } from 'notistack'
import { Suspense, useEffect, useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import GatekeeperApi from '../../api/GatekeeperApi'
import { env } from '../../index.js'
import UserEmailNotificationsTable from '../gridComponents/UserEmailNotificationsTable'
import { COMPANY_TAGS } from './UserDrawer'
import ItemTimeline from '../gridComponents/ItemTimeline'
import RegionSelector from '../controls/RegionSelector'

const classes = {
  accordianList: {
    marginTop: '15px',
    marginLeft: '15px',
    borderRadius: '5px',
    width: '89.5%',
    boxShadow: 'none',
    border: '1px solid rgb(196, 196, 196)',
    '&:before': {
      display: 'none'
    }
  },
  accordianSummary: {
    width: '100%',
    borderRadius: '5px',
    minHeight: '40px',
    fontSize: '16px'
  },
  accordianDetails: { padding: '0px', margin: '0px', marginTop: '0px' },
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    color: 'black'
  }
}

const URL_MAP = {
  notifications: 0,
  history: 1,
  '': 0
}

export default function UserSalesDrawer(props) {
  const { instance, accounts } = useMsal()
  let localizedStrings = useWtxLocalization()
  const [groups, setGroups] = useState([])
  const [tags, setTags] = useState([])
  const [state, setState] = useState('notifications')
  const [widgetsDisabled, setWidgetsDisabled] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [activationStatus, setActivationStatus] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const { data: userData, refetch: userRefetch } = useQuery({
    queryKey: ['user', selectedUser],
    queryFn: async () => await GatekeeperApi.getUserById(instance, accounts, selectedUser.id),
    enabled: selectedUser !== null
  })

  const { data: regionData } = useQuery({
    queryKey: ['regions'],
    queryFn: async () => await GatekeeperApi.getRegions(instance, accounts)
  })

  const continentCountries = useMemo(() => {
    if (regionData) {
      let continentMap = regionData.reduce((map, obj) => {
        if (obj.continent in map) {
          map[obj.continent].push({ id: obj.id, value: obj.name })
        } else {
          map[obj.continent] = [{ id: obj.id, value: obj.name }]
        }
        return map
      }, {})

      //sort alphabetically with matching at top
      Object.keys(continentMap).forEach(continent => {
        continentMap[continent].sort((a, b) => {
          // Make sure the value that matches the key comes first
          if (a.value === continent) return -1
          if (b.value === continent) return 1
          // Otherwise, sort alphabetically by value
          return a.value.localeCompare(b.value)
        })
      })

      return Object.keys(continentMap)
        .sort((a, b) => {
          if (a === 'North America') return -1
          if (b === 'North America') return 1
          // Otherwise, sort alphabetically by value
          return a.localeCompare(b)
        })
        .map(continent => ({ continent: continent, regions: continentMap[continent] }))
    }
  }, [regionData])

  const regionIdInfo = useMemo(() => {
    let result = {}
    if (regionData) {
      result = regionData.reduce((map, obj) => {
        map[obj.id] = obj
        return map
      }, {})
    }
    return result
  }, [regionData])

  useEffect(() => {
    if (props.user) {
      setSelectedUser(props.user)
    }
  }, [props.user])

  useEffect(() => {
    if (userData && userData.activationStatus) {
      setActivationStatus(userData.activationStatus)
    }
    if (userData && userData.tags) {
      setTags([...userData.tags])
    }
  }, [userData])

  const onClose = async () => {
    props.onClose()
    setGroups([])
    await props.refetch()
  }

  useEffect(() => {
    if (props.groups) {
      setGroups(props.groups)
    }
  }, [props.groups])

  async function tagChipClick(tag, tagExists) {
    if (tagExists) {
      //remove
      let key = enqueueSnackbar('Removing tag from group...', SnackbarVariants.LOADING)
      await GatekeeperApi.removeTagFromUser(instance, accounts, selectedUser.id, tag.id)
        .then(async () => {
          closeSnackbar(key)
          userRefetch()
          enqueueSnackbar(`Successfully removed ${tag.value} tag`, SnackbarVariants.SUCCESS)
        })
        .catch(() => {
          closeSnackbar(key)
          enqueueSnackbar(`An error occurred when removing ${tag.value} tag`, SnackbarVariants.ERROR)
        })
    } else {
      //add
      let key = enqueueSnackbar('Adding tag to group...', SnackbarVariants.LOADING)
      await GatekeeperApi.addTagToUser(instance, accounts, selectedUser.id, tag.id)
        .then(async () => {
          closeSnackbar(key)
          userRefetch()
          enqueueSnackbar(`Successfully added ${tag.value} tag`, SnackbarVariants.SUCCESS)
        })
        .catch(() => {
          closeSnackbar(key)
          enqueueSnackbar(`An error occurred when adding ${tag.value} tag`, SnackbarVariants.ERROR)
        })
    }
  }

  async function updateRegion(region) {
    let key = enqueueSnackbar('Updating user region...', SnackbarVariants.LOADING)
    await GatekeeperApi.updateUserRegion(instance, accounts, selectedUser.id, region)
      .then(async () => {
        closeSnackbar(key)
        userRefetch()
        enqueueSnackbar(`Successfully update user region to ${region.name}`, SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar(`An error occurred when update user region`, SnackbarVariants.ERROR)
      })
  }

  return (
    <SlideUpDialog
      id='userSalesDrawer'
      buttonsDisabled={widgetsDisabled}
      fullScreen
      open={props.open}
      onClose={onClose}
      title={<h3 style={{ marginTop: '0px' }}>{localizedStrings.manageUser}</h3>}
    >
      <>
        <Grid container>
          <Grid item md={12} lg={4}>
            <Card sx={{ margin: '15px' }}>
              <CardContent>
                <TextField
                  id='userNameTextField'
                  disabled
                  style={{ width: '90%', marginTop: 30, marginLeft: 15 }}
                  label={'Name'}
                  size='small'
                  value={selectedUser ? `${selectedUser.givenName} ${selectedUser.surname}` : ''}
                  variant='outlined'
                />
                <TextField
                  id='userCompanyTextField'
                  disabled
                  style={{ width: '90%', marginTop: 15, marginLeft: 15 }}
                  label={'Company'}
                  size='small'
                  value={selectedUser ? `${selectedUser.company}` : ''}
                  variant='outlined'
                />
                <TextField
                  id='userEmailTextField'
                  disabled
                  style={{ width: '90%', marginTop: 15, marginLeft: 15 }}
                  label={'Email'}
                  size='small'
                  value={selectedUser ? `${selectedUser.email}` : ''}
                  variant='outlined'
                />
                {selectedUser && selectedUser.type === 'ExternalUser' ? (
                  <RegionSelector
                    id='userRegionCustomSelect'
                    style={{ width: '90%', marginTop: 15, marginLeft: 15 }}
                    label={localizedStrings.region}
                    value={
                      regionIdInfo && selectedUser && regionIdInfo[selectedUser.country]
                        ? regionIdInfo[selectedUser.country].id
                        : ''
                    }
                    onChange={e => {
                      setSelectedUser(f => ({ ...f, country: e.target.value }))
                      updateRegion(regionIdInfo[e.target.value])
                    }}
                    options={continentCountries}
                    regionIdInfo={regionIdInfo}
                  />
                ) : null}
                {tags ? (
                  <>
                    <CardHeader subheader='Company Tags' style={{ textAlign: 'center' }} />
                    <Card variant='outlined' sx={{ padding: '15px' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {COMPANY_TAGS.map(tag => (
                          <Chip
                            id={`${tag.id}Chip`}
                            key={tag.id}
                            label={tag.value}
                            sx={{
                              margin: '2px',
                              color: tags.includes(tag.id) ? 'white' : 'black',
                              backgroundColor: tags.includes(tag.id) ? WtxColors.IQ_BLUE : 'white'
                            }}
                            onClick={async () => tagChipClick(tag, tags.includes(tag.id))}
                          />
                        ))}
                      </Box>
                    </Card>
                  </>
                ) : null}
                {groups ? (
                  <CustomAccordion
                    defaultExpanded
                    title='Groups'
                    style={{ marginTop: '15px', width: '100%' }}
                    children={
                      <List>
                        {groups.map(group => {
                          return (
                            <ListItem disablePadding key={group.id}>
                              <ListItemButton
                                id={`${group.id}OpenNewTabButton`}
                                onClick={() => {
                                  let queryString = new URLSearchParams({
                                    id: group.id,
                                    isDealerGroup: group.dealerGroup
                                  }).toString()

                                  window.open(`/group/overview?${queryString}`, '_blank')
                                }}
                              >
                                <div>{group.groupName} </div>
                              </ListItemButton>
                            </ListItem>
                          )
                        })}
                      </List>
                    }
                  />
                ) : (
                  <span />
                )}
                <HasAccess allowedRoles={['Sales Admin']} env={env}>
                  {selectedUser && selectedUser.type === 'ExternalUser' ? (
                    <>
                      {activationStatus === 'Deactivated' ? (
                        <CenteredDiv>
                          <SuccessButton
                            id='activateUserButton'
                            disabled={widgetsDisabled}
                            style={{
                              marginTop: '10px',
                              width: '200px'
                            }}
                            onClick={async () => {
                              setWidgetsDisabled(true)
                              await GatekeeperApi.activateUser(instance, accounts, selectedUser)
                                .then(result => {
                                  enqueueSnackbar('User activated successfully', SnackbarVariants.SUCCESS)
                                  userRefetch()
                                })
                                .catch(e =>
                                  enqueueSnackbar(`Error activating user: ${e.response.message}`, SnackbarVariants.ERROR)
                                )
                              setWidgetsDisabled(false)
                            }}
                          >
                            Activate
                          </SuccessButton>
                        </CenteredDiv>
                      ) : (
                        <CenteredDiv>
                          <DeleteButton
                            id='deactivateUserButton'
                            disabled={widgetsDisabled}
                            style={{
                              marginTop: '10px',
                              width: '200px'
                            }}
                            onClick={async () => {
                              setWidgetsDisabled(true)
                              await GatekeeperApi.deactivateUser(instance, accounts, selectedUser)
                                .then(result => {
                                  enqueueSnackbar('User deactivated successfully', SnackbarVariants.SUCCESS)
                                  userRefetch()
                                })
                                .catch(e =>
                                  enqueueSnackbar(`Error deactivating user: ${e.response.message}`, SnackbarVariants.ERROR)
                                )
                              setWidgetsDisabled(false)
                            }}
                          >
                            Deactivate
                          </DeleteButton>
                        </CenteredDiv>
                      )}
                    </>
                  ) : null}
                </HasAccess>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12} lg={8}>
            <div style={{ width: '100%', margin: '15px 0px', paddingLeft: '15px' }}>
              <Tabs value={state ? URL_MAP[state] : 1} variant='scrollable' scrollButtons='auto'>
                {['Notifications', 'History'].map(p => {
                  return (
                    <Tab
                      id={`${p}Tab`}
                      key={p}
                      sx={classes.tab}
                      label={p}
                      onClick={() => {
                        setState(p.toLowerCase())
                      }}
                    />
                  )
                })}
              </Tabs>
              <Suspense
                fallback={
                  <CenteredDiv>
                    <CircularProgress />
                  </CenteredDiv>
                }
              >
                {
                  {
                    notifications: (
                      <UserEmailNotificationsTable
                        user={userData}
                        widgetsDisabled={widgetsDisabled}
                        setWidgetsDisabled={setWidgetsDisabled}
                      />
                    ),
                    history: <ItemTimeline history={userData ? userData.history : []} />,
                    '': <div>Error</div>
                  }[state]
                }
              </Suspense>
            </div>
          </Grid>
        </Grid>
      </>
    </SlideUpDialog>
  )
}
