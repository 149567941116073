import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import { CenteredDiv } from '@wavetronix/common-components'
import { useEffect, useState } from 'react'

//Filter View Components

const isSelected = (selectedOptions, id) => {
  if (!selectedOptions) {
    return false
  }
  var selected = selectedOptions
    .map(option => option.id === id)
    .reduce((previousValue, currentValue) => previousValue || currentValue, false)
  return selected
}

//Example of onChange -> onChange={products => setFilter(f => ({ ...f, productTypes: products }))}
//selected option needs an id and a value (value is what will be displayed)
export default function CountriesListSelector({ selectedOptions, options, continentCountries, onChange, id, style }) {
  const [selectedMap, setSelectedMap] = useState({})
  const [prevId, setPrevId] = useState(id)

  useEffect(() => {
    if (
      id !== prevId ||
      Object.keys(selectedMap).length !== options.length ||
      options.some(p => !(p.value in selectedMap)) ||
      Object.values(selectedMap).filter(v => v === true).length !== selectedOptions.length
    ) {
      let map = {}
      for (let option of options) {
        map[option.value] = isSelected(selectedOptions, option.id)
      }
      setSelectedMap(map)
      setPrevId(id)
    }
  }, [options, selectedOptions, selectedMap, id, prevId])

  function handleOnClick(option) {
    let map = {}

    for (let value in selectedMap) {
      if (value === option.value) {
        map[value] = !selectedMap[value]
      } else {
        map[value] = selectedMap[value]
      }
    }

    setSelectedMap(map)

    let selected = options
      .filter(option => map[option.value])
      .map(option => {
        let newSelected = { ...option }
        delete newSelected.isSelected
        return newSelected
      })
    let returned = Object.keys(selected).map(option => {
      return { id: selected[option].id, value: selected[option].value }
    })
    onChange(returned)
  }

  if (!selectedMap) {
    return <CenteredDiv></CenteredDiv>
  }

  return (
    <List
      sx={{
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: '200px',
        ...style,
        '& ul': { padding: 0 }
      }}
      subheader={<li />}
    >
      {continentCountries
        ? continentCountries.map(regionOption => (
            <li key={`section-${regionOption.continent}`}>
              <ul>
                <ListSubheader>{`${regionOption.continent}`}</ListSubheader>
                {regionOption.regions.map(option => (
                  <ListItem id={option.id} key={option.id} disablePadding>
                    <ListItemButton id={`${option.id}Button`} role={undefined} onClick={e => handleOnClick(option)} dense>
                      <ListItemIcon sx={{ minWidth: '20px' }}>
                        <Checkbox
                          id={`${option.id}Checkbox`}
                          size='small'
                          edge='start'
                          sx={{ padding: '0px', paddingLeft: '8px' }}
                          checked={selectedMap[option.value] ? selectedMap[option.value] : false}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText id={`${option.id}Text`} primary={option.value} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </ul>
            </li>
          ))
        : []}
    </List>
  )
}
