import { CustomDataGrid } from '@wavetronix/common-components'
import React, { useState } from 'react'
import { Box } from '@mui/material'
import { getDeviceName } from '../../utils/PermissionsMap'
import compareAlphabetically from '../../utils/compareAlphabetically'

export default function GroupsUpdatesTable({ updates, isLoading, overridesComparator, renderSwitch }) {
  const columns = [
    {
      field: 'override',
      headerName: 'Override',
      flex: 0.75,
      valueGetter: params => params.row.id,
      disableColumnMenu: true,
      sortComparator: overridesComparator,
      renderCell: data => renderSwitch(data.row),
      hideable: false
    },
    {
      field: 'baseProductId',
      headerName: 'Type',
      flex: 1,
      sortComparator: compareAlphabetically,
      valueGetter: u => getDeviceName(u.value),
      filterable: false,
      hideable: false
    },
    { field: 'itemNumber', headerName: 'Item No.', flex: 1, hide: true, filterable: false },
    { field: 'version', headerName: 'Version No.', flex: 1, filterable: false },
    { field: 'releasePhase', headerName: 'Release Phase', flex: 1, filterable: false },
    { field: 'launchPhase', headerName: 'Launch Phase', flex: 1, filterable: false }
  ]

  const [pageSize, setPageSize] = useState(20)

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <CustomDataGrid
          rows={updates}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={p => setPageSize(p.pageSize)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          disableSelectionOnClick
          loading={isLoading}
          disableExtendRowFullWidth={true}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'override',
                  sort: 'asc'
                }
              ]
            }
          }}
        />
      </Box>
    </>
  )
}
