import { Switch, Box } from '@mui/material'
import { PrimaryButton, PersistantFilterDiv, SnackbarVariants } from '@wavetronix/common-components'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo, useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSnackbar } from 'notistack'

import FilterDrawer, { DEFAULT_FILTER, filterUsers } from '../../drawers/FilterDrawer'
import GatekeeperApi from '../../../api/GatekeeperApi'
import GroupsUsersTable from '../../gridComponents/GroupsUsersTable'

export default function EditGroupUsers({
  isDealerGroup,
  group,
  setGroup,
  instance,
  accounts,
  isUploading,
  setIsUploading,
  usersData,
  usersIsLoading,
  usersMap,
  isReadOnly,
  updateGroupChangeType,
  myUser,
  hasChanges,
  setHasChanges
}) {
  const theme = useTheme()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen
  const [userFilter, setUserFilter] = useState(DEFAULT_FILTER)
  const [changedUsers, setChangedUsers] = useState([])

  const { data: regionData } = useQuery({
    queryKey: ['regions'],
    queryFn: async () => await GatekeeperApi.getRegions(instance, accounts)
  })

  useEffect(() => {
    if (changedUsers) {
      setHasChanges(changedUsers.length > 0 ? true : false)
    }
  }, [changedUsers, setHasChanges])

  const canAddUsers = useMemo(() => {
    if (group && myUser) {
      if (isDealerGroup) {
        return group.admins.includes(myUser.id)
      } else {
        return group.permissableAdmins.includes('Sales Admin') && myUser.roles.includes('Sales Team Member')
      }
    }
  }, [group, myUser, isDealerGroup])

  const filteredUsers = useMemo(() => {
    if (usersData && userFilter && regionData) {
      let localUserFilter = { ...userFilter }
      if (isDealerGroup) {
        localUserFilter.locations = regionData
          ? regionData
              .filter(r => {
                return group.dealerStates.includes(r.name)
              })
              .map(r => {
                return { id: r.id, value: r.name }
              })
          : []
      }
      return filterUsers(localUserFilter, usersData)
    }
  }, [usersData, userFilter, group, isDealerGroup, regionData])

  async function onUserSwitchChange(e, user) {
    setIsUploading(true)
    if (usersMap[user.id].isCurrentGroupMember) {
      if (e.target.checked) {
        setChangedUsers(change => [...change.filter(u => u !== user.id)])
      } else {
        setChangedUsers(change => [...change, user.id])
      }
    } else {
      if (e.target.checked) {
        setChangedUsers(change => [...change, user.id])
      } else {
        setChangedUsers(change => [...change.filter(u => u !== user.id)])
      }
    }

    setIsUploading(false)
  }

  async function updateUsersForGroup(idsToAdd, idsToRemove) {
    let key = enqueueSnackbar('Updating users for group...', SnackbarVariants.LOADING)
    let addPromises = idsToAdd.map(id => GatekeeperApi.addGroupToUser(instance, accounts, id, group.id))
    let removePromises = idsToRemove.map(id => GatekeeperApi.removeGroupFromUser(instance, accounts, id, group.id))

    await Promise.all([...addPromises, ...removePromises])
      .then(async () => {
        enqueueSnackbar('Successfully update users for group', SnackbarVariants.SUCCESS)
      })
      .catch(() => {
        enqueueSnackbar('An error occurred when updating users for group', SnackbarVariants.ERROR)
      })
      .finally(() => {
        closeSnackbar(key)
      })
  }

  const generateListOfChangedUserIds = () => {
    let usersToAdd = changedUsers.filter(uId => usersMap[uId].isCurrentGroupMember === false)
    let usersToRemove = changedUsers.filter(uId => usersMap[uId].isCurrentGroupMember === true)
    return { idsToAdd: usersToAdd, idsToRemove: usersToRemove }
  }

  const generateNotesForUsers = ids => {
    let notes = []

    if (ids.idsToAdd.length > 0) notes.push(`Users added: ${ids.idsToAdd.map(userId => usersMap[userId].name).join(', ')}`)
    if (ids.idsToRemove.length > 0)
      notes.push(`Users removed: ${ids.idsToRemove.map(userId => usersMap[userId].name).join(', ')}`)

    return notes
  }

  return (
    <Box>
      <PersistantFilterDiv
        defaultOpen={largeScreenFeaturesActive}
        resetFilter={() => setUserFilter(DEFAULT_FILTER)}
        drawer={<FilterDrawer setFilter={setUserFilter} filter={userFilter} countries={regionData} dealerGroup={isDealerGroup} />}
        page={
          <div>
            <Box sx={{ float: 'right' }}>
              <PrimaryButton
                style={{ width: '200px' }}
                disabled={isUploading || changedUsers.length === 0 || (!canAddUsers && isReadOnly)}
                onClick={async () => {
                  setIsUploading(true)
                  const changedIds = generateListOfChangedUserIds()
                  updateUsersForGroup(changedIds.idsToAdd, changedIds.idsToRemove)
                  await updateGroupChangeType('users', [], generateNotesForUsers(changedIds))
                  setChangedUsers([])
                  setIsUploading(false)
                }}
              >
                Save
              </PrimaryButton>
            </Box>
            <GroupsUsersTable
              groupId={group.id}
              isDealerGroup={isDealerGroup}
              filter={userFilter}
              isLoading={usersIsLoading}
              setFilter={setUserFilter}
              users={filteredUsers ? filteredUsers : []}
              overridesComparator={(a, b) => {
                let AInGroup = usersMap[a] ? usersMap[a].groups.includes(group.id) : false
                let BInGroup = usersMap[b] ? usersMap[b].groups.includes(group.id) : false
                if (AInGroup === true && BInGroup === false) {
                  return -1
                } else if (AInGroup === false && BInGroup === true) {
                  return 1
                } else {
                  return 0
                }
              }}
              renderSwitch={user => {
                return (
                  <Switch
                    checked={
                      usersMap && usersMap[user.id] && changedUsers
                        ? usersMap[user.id].isCurrentGroupMember !== changedUsers.includes(user.id)
                        : false
                    }
                    color={
                      usersMap && usersMap[user.id] && changedUsers
                        ? usersMap[user.id].isCurrentGroupMember !== changedUsers.includes(user.id)
                          ? 'primary'
                          : 'secondary'
                        : 'secondary'
                    }
                    onChange={e => onUserSwitchChange(e, user)}
                    disabled={isUploading || canAddUsers ? !canAddUsers : isReadOnly}
                  />
                )
              }}
            />
          </div>
        }
      />
    </Box>
  )
}
