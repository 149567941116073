import { useEffect, useState, useRef } from 'react'

// const printSpecial = (message, stateKey, wantedStateKey) => {
//   if (stateKey === wantedStateKey) {
//     console.log(message)
//   }
// }

//https://javascript.plainenglish.io/a-react-hook-to-share-state-between-browser-windows-a672470f66ff
export function useCrossTabState(stateKey, defaultValue) {
  const [state, setState] = useState(defaultValue)
  const isNewSession = useRef(true)

  useEffect(() => {
    if (isNewSession.current) {
      const currentState = localStorage.getItem(stateKey)
      if (currentState) {
        setState(JSON.parse(currentState))
      } else {
        setState(defaultValue)
      }
      isNewSession.current = false
      return
    }

    try {
      localStorage.setItem(stateKey, JSON.stringify(state))
    } catch (error) {}
  }, [state, stateKey, defaultValue])

  useEffect(() => {
    const onReceieveMessage = e => {
      const { key, newValue } = e
      if (key === stateKey) {
        setState(JSON.parse(newValue))
      }
    }
    window.addEventListener('storage', onReceieveMessage)
    return () => window.removeEventListener('storage', onReceieveMessage)
  }, [stateKey, setState])
  return [state, setState]
}

export function clearState(stateKey, defaultValue) {
  try {
    localStorage.setItem(stateKey, JSON.stringify(defaultValue))
  } catch (error) {}
}
