import { useEffect, useMemo } from 'react'

import dayjs from 'dayjs'

import { TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  CustomAccordion,
  CustomSelect,
  HasAccess,
  inDateRange,
  ListSelector,
  useWtxLocalization
} from '@wavetronix/common-components'
import CountriesListSelector from '../controls/CountriesListSelector'
import { env } from '../../index.js'
import { COMPANY_TAGS } from '../modals/UserDrawer'
import RolesSelector from '../controls/RolesSelector'

export const DEFAULT_FILTER = {
  name: '',
  givenName: '',
  surname: '',
  email: '',
  company: '',
  groups: [],
  roles: [],
  createdDate: dayjs().subtract(1, 'w'),
  showRecentOnly: false,
  activationStatus: 'All Users',
  locations: [],
  tags: []
}

const nullCheck = s => (s ? s : '')

export const filterUsers = (filter, users, usersGroupRoles) =>
  users.filter(user => {
    const isNameMatch = nullCheck(`${user.givenName} ${user.surname}`).toLowerCase().includes(filter.name.toLowerCase())
    const isGivenNameMatch = nullCheck(user.givenName).toLowerCase().includes(filter.givenName.toLowerCase())
    const isSurnameMatch = nullCheck(user.surname).toLowerCase().includes(filter.surname.toLowerCase())
    const isCompanyMatch = nullCheck(user.company).toLowerCase().includes(filter.company.toLowerCase())
    const isEmailMatch = nullCheck(user.email).toLowerCase().includes(filter.email.toLowerCase())

    const isCountryMatch =
      filter.locations.map(location => location.id).includes(nullCheck(user.country)) || filter.locations.length === 0

    const isGroupMatch =
      nullCheck(user.groups).some(group => filter.groups.map(g => g.id).includes(group)) || filter.groups.length === 0

    const isRoleMatch =
      user.roles.some(role => filter.roles.map(r => r.id).includes(role)) ||
      (usersGroupRoles &&
        usersGroupRoles[user.id] &&
        usersGroupRoles[user.id].some(role => filter.roles.map(r => r.id).includes(role))) ||
      filter.roles.length === 0

    const isRecentMatch =
      filter.showRecentOnly === false ||
      (user.createdDate && inDateRange(user.createdDate, filter.createdDate, null) && filter.showRecentOnly === true)

    const isActivationMatch =
      filter.activationStatus === 'Activated' || filter.activationStatus === 'Deactivated'
        ? user.activationStatus === filter.activationStatus
        : true

    const isTagMatch =
      !user.tags || filter.tags.length === 0 || user.tags.some(tag => filter.tags.map(t => t.id).includes(tag)) || false

    return (
      isNameMatch &&
      isGivenNameMatch &&
      isSurnameMatch &&
      isCompanyMatch &&
      isEmailMatch &&
      isCountryMatch &&
      isGroupMatch &&
      isRoleMatch &&
      isRecentMatch &&
      isActivationMatch &&
      isTagMatch
    )
  })

export default function FilterDrawer({ filter, setFilter, groups, countries, hasDeactivated, dealerGroup }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const continentCountries = useMemo(() => {
    if (countries) {
      let continentMap = countries.reduce((map, obj) => {
        if (obj.continent in map) {
          map[obj.continent].push({ id: obj.id, value: obj.name })
        } else {
          map[obj.continent] = [{ id: obj.id, value: obj.name }]
        }
        return map
      }, {})

      //sort alphabetically with matching at top
      Object.keys(continentMap).forEach(continent => {
        continentMap[continent].sort((a, b) => {
          // Make sure the value that matches the key comes first
          if (a.value === continent) return -1
          if (b.value === continent) return 1
          // Otherwise, sort alphabetically by value
          return a.value.localeCompare(b.value)
        })
      })

      return Object.keys(continentMap)
        .sort((a, b) => {
          if (a === 'North America') return -1
          if (b === 'North America') return 1
          // Otherwise, sort alphabetically by value
          return a.localeCompare(b)
        })
        .map(continent => ({ continent: continent, regions: continentMap[continent] }))
    }
  }, [countries])

  useEffect(() => {
    setFilter(f => ({ ...f, tags: COMPANY_TAGS.filter(t => t.id === env.basicAuthentication.fromCompany) }))
  }, [setFilter])

  let localizedStrings = useWtxLocalization()

  return (
    <>
      {isSmallScreen ? (
        <TextField
          id='filterNameTextField'
          label='Name'
          size='small'
          variant='outlined'
          style={{ width: '100%' }}
          onChange={e => setFilter(s => ({ ...s, name: e.target.value }))}
          value={filter.name ? filter.name : ''}
        />
      ) : (
        <>
          <TextField
            id='filterFirstNameTextField'
            label={localizedStrings.firstName}
            size='small'
            variant='outlined'
            style={{ width: '100%' }}
            onChange={e => setFilter(s => ({ ...s, givenName: e.target.value }))}
            value={filter.givenName ? filter.givenName : ''}
          />
          <TextField
            id='filterFirstLastTextField'
            label={localizedStrings.lastName}
            size='small'
            variant='outlined'
            style={{ width: '100%', marginTop: '15px' }}
            onChange={e => setFilter(s => ({ ...s, surname: e.target.value }))}
            value={filter.surname ? filter.surname : ''}
          />
        </>
      )}
      <TextField
        id='filterEmailTextField'
        label={localizedStrings.email}
        size='small'
        variant='outlined'
        style={{ width: '100%', marginTop: '15px' }}
        onChange={e => setFilter(s => ({ ...s, email: e.target.value }))}
        value={filter.email ? filter.email : ''}
      />

      <TextField
        id='filterCompanyTextField'
        label={localizedStrings.company}
        size='small'
        variant='outlined'
        style={{ width: '100%', marginTop: '15px' }}
        onChange={e => setFilter(s => ({ ...s, company: e.target.value }))}
        value={filter.company ? filter.company : ''}
      />
      <CustomAccordion title={'Company Tags'} style={{ marginTop: '15px' }} defaultExpanded>
        <ListSelector selectedOptions={filter.tags} options={COMPANY_TAGS} onChange={e => setFilter({ ...filter, tags: e })} />
      </CustomAccordion>

      {countries && !dealerGroup ? (
        <>
          <CustomAccordion
            defaultExpanded
            style={{ width: '100%', marginTop: '15px' }}
            title={localizedStrings.region}
            children={
              <CountriesListSelector
                selectedOptions={filter.locations}
                options={countries ? countries.map(countries => ({ id: countries.id, value: countries.name })) : []}
                continentCountries={continentCountries}
                onChange={e => {
                  setFilter({ ...filter, locations: e })
                }}
              />
            }
          />
        </>
      ) : null}

      {groups ? (
        <>
          <CustomAccordion
            title={localizedStrings.groups}
            style={{ marginTop: '15px' }}
            children={
              <ListSelector
                selectedOptions={filter.groups}
                options={[
                  ...(groups
                    ? groups
                        .map(group => ({ id: group.id, value: group.groupName }))
                        .sort((a, b) => {
                          let fa = a.value,
                            fb = b.value
                          if (fa < fb) {
                            return -1
                          }
                          if (fa > fb) {
                            return 1
                          }
                          return 0
                        })
                    : [])
                ]}
                onChange={e => setFilter({ ...filter, groups: e })}
              />
            }
          />
        </>
      ) : null}
      <HasAccess allowedRoles={['Gatekeeper Admin']} env={env}>
        <RolesSelector filter={filter} setFilter={setFilter} />
      </HasAccess>
      {hasDeactivated ? (
        <>
          <CustomSelect
            id='filterActivationStatusSelect'
            style={{ marginTop: 15, width: '100%' }}
            label='Activation Status'
            value={filter.activationStatus}
            onChange={e => setFilter(filter => ({ ...filter, activationStatus: e.target.value }))}
            options={['Activated', 'Deactivated', 'All Users']}
          />
        </>
      ) : null}
    </>
  )
}
