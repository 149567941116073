import { CustomDataGrid } from '@wavetronix/common-components'
import { Box } from '@mui/material'
import React, { useState } from 'react'

export default function GroupsPackagesTable({ packages, isLoading, overridesComparator, renderSwitch }) {
  const columns = [
    {
      field: 'override',
      headerName: 'Override',
      flex: 1,
      valueGetter: params => params.row.id,
      disableColumnMenu: true,
      sortComparator: overridesComparator,
      renderCell: data => renderSwitch(data.row)
    },
    {
      headerName: 'Package Name',
      field: 'name',
      flex: 4
    }
  ]

  const [pageSize, setPageSize] = useState(20)

  return (
    <div style={{ marginTop: '15px' }}>
      <Box sx={{ width: '100%' }}>
        <CustomDataGrid
          rows={packages}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={p => setPageSize(p.pageSize)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          disableSelectionOnClick
          loading={isLoading}
          disableExtendRowFullWidth={true}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'override',
                  sort: 'asc'
                }
              ]
            }
          }}
        />
      </Box>
    </div>
  )
}
