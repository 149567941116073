import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab'
import { WtxColors, useWtxLocalization } from '@wavetronix/common-components'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'

export default function ItemTimeline({ history }) {
  const [reversedHistory, setReversedHistory] = useState([])
  const localizedStrings = useWtxLocalization()
  useEffect(() => {
    if (history) {
      let rev = [...history]
      rev.reverse()
      setReversedHistory([...rev])
    }
  }, [history])

  return (
    <Timeline position='alternate' sx={{ maxHeight: '100vh', overflow: 'auto' }}>
      {reversedHistory.map((item, i) => {
        let localTimeTranslate = dayjs(item.occurredOn).local().format('MM/DD/YYYY hh:mm A')
        return (
          <TimelineItem key={`timeline-${i}`}>
            <TimelineSeparator>
              <TimelineDot />
              {i < reversedHistory.length - 1 ? <TimelineConnector /> : null}
            </TimelineSeparator>
            <TimelineContent>
              {localTimeTranslate}
              <div style={{ color: WtxColors.IQ_BLUE }}>
                {item.modificationType} {localizedStrings.by} {item.requestingEntityName}
              </div>
              <div style={{ fontStyle: 'italic' }}>{item.notes}</div>
            </TimelineContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  )
}
