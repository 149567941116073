import { useMsal } from '@azure/msal-react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import MasterFeaturesApi from '../../api/MasterFeaturesApi'
import UpdatesApi from '../../api/UpdatesApi'
import { getDeviceName } from '../../utils/PermissionsMap'

export default function SelectBaseProduct({ style, required, onChange, value, label, showAll }) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar } = useSnackbar()
  const { data, isLoading } = useQuery({
    queryKey: ['baseProducts'],
    queryFn: async () =>
      showAll
        ? await MasterFeaturesApi.getBaseProducts(instance, accounts)
        : await UpdatesApi.getUpdates(instance, accounts, false)
            .then(updates => Array.from(new Set(updates.map(u => u.baseProductId))))
            .catch(() => enqueueSnackbar('Error while retrieving base product ids.'))
  })

  return (
    <FormControl style={style} required={required} variant='outlined' size='small'>
      <InputLabel id='selectBaseProductComponentLabel'>{label}</InputLabel>
      <Select
        id='selectBaseProductSelect'
        labelId='selectBaseProductComponentLabel'
        value={data ? value : ''}
        onChange={onChange}
        label={label}
      >
        {!isLoading && data
          ? data.map(id => (
              <MenuItem id={`${id}MenuItem`} value={id} key={id}>
                {getDeviceName(id)}
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  )
}
