import { Switch, Box } from '@mui/material'
import { PrimaryButton, PersistantFilterDiv } from '@wavetronix/common-components'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import PackagesFilterDrawer, { DEFAULT_PACKAGES_FILTER, filterPackages } from '../../drawers/PackagesFilterDrawer'
import PackagesApi from '../../../api/PackagesApi'
import GroupsPackagesTable from '../../gridComponents/GroupsPackagesTable'
import { listHaveDifferences } from '../../../utils/arrayUtils.js'

export default function EditGroupPackages({
  groupData,
  group,
  setGroup,
  instance,
  accounts,
  isUploading,
  setIsUploading,
  isReadOnly,
  updateGroupChangeType,
  generateListDiff,
  hasChanges,
  setHasChanges
}) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen
  const [packagesFilter, setPackagesFilter] = useState(DEFAULT_PACKAGES_FILTER)

  const { data: packagesData, isLoading: packagesIsLoading } = useQuery({
    queryKey: ['packages'],
    queryFn: async () => await PackagesApi.getPackages(instance, accounts)
  })

  useEffect(() => {
    if (group && groupData) {
      let hasDiff = listHaveDifferences(group.packages, groupData.packages)
      setHasChanges(hasDiff)
    }
  }, [group, groupData, setHasChanges])

  const filteredPackages = useMemo(() => {
    if (packagesData && packagesFilter) {
      return filterPackages(packagesFilter, packagesData)
    }
  }, [packagesData, packagesFilter])

  const packagesMap = useMemo(() => {
    if (packagesData) {
      return packagesData.reduce((map, obj) => {
        map[obj.id] = { ...obj }
        return map
      }, [])
    }
  }, [packagesData])

  async function onSwitchChange(e, pckg) {
    setIsUploading(true)
    if (e.target.checked) {
      setGroup(g => ({ ...g, packages: [...g.packages, pckg.id] }))
    } else {
      setGroup(g => ({ ...g, packages: g.packages.filter(id => id !== pckg.id) }))
    }
    setIsUploading(false)
  }

  const generateNotes = ids => {
    let notes = []

    if (hasChanges) {
      generateListDiff(notes, 'packages', 'Packages', packagesMap, 'name')
    }

    return notes
  }

  return (
    <Box sx={{ display: 'block' }}>
      <PersistantFilterDiv
        defaultOpen={largeScreenFeaturesActive}
        resetFilter={() => setPackagesFilter(DEFAULT_PACKAGES_FILTER)}
        drawer={<PackagesFilterDrawer setFilter={setPackagesFilter} filter={packagesFilter} />}
        page={
          <>
            <Box sx={{ float: 'right', width: '100%' }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <PrimaryButton
                style={{ width: '200px', float: 'right', marginBottom: '15px' }}
                disabled={isUploading || isReadOnly || hasChanges ? !hasChanges : true}
                onClick={async () => {
                  setIsUploading(true)
                  await updateGroupChangeType('packages', group.packages, generateNotes(group.packages))
                  setIsUploading(false)
                }}
              >
                Save
              </PrimaryButton>
            </Box>
            <Box sx={{ width: '100%' }}>
              <GroupsPackagesTable
                packages={filteredPackages ? filteredPackages : []}
                isLoading={packagesIsLoading}
                overridesComparator={(a, b) => {
                  let AInGroup = group.packages ? group.packages.includes(a) : false
                  let BInGroup = group.packages ? group.packages.includes(b) : false
                  if (AInGroup === true && BInGroup === false) {
                    return -1
                  } else if (AInGroup === false && BInGroup === true) {
                    return 1
                  } else {
                    return 0
                  }
                }}
                renderSwitch={pckg => (
                  <Switch
                    checked={group.packages ? group.packages.includes(pckg.id) : false}
                    color={group.packages && group.packages.includes(pckg.id) ? 'primary' : 'secondary'}
                    onChange={e => onSwitchChange(e, pckg)}
                    disabled={isUploading || isReadOnly}
                  />
                )}
              />
            </Box>
          </>
        }
      />
    </Box>
  )
}
