import { DialogContent } from '@mui/material'
import { useWtxLocalization, RegularButton, SlideUpDialog, DeleteButton } from '@wavetronix/common-components'

export default function VerifyDeleteModal({ open, onClose, onDelete, message, title, buttonsDisabled }) {
  const localizedStrings = useWtxLocalization()

  return (
    <SlideUpDialog
      id='verifyDeleteModal'
      buttonsDisabled={buttonsDisabled}
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      title={<h3 style={{ margin: 0 }}>{title}</h3>}
      actions={
        <>
          <RegularButton id='cancelVerifyDeleteButton' disabled={buttonsDisabled} onClick={onClose}>
            {localizedStrings.cancel}
          </RegularButton>
          <DeleteButton id='deleteVerifyDeleteButton' disabled={buttonsDisabled} onClick={onDelete}>
            Delete
          </DeleteButton>
        </>
      }
    >
      <DialogContent>
        <h3 style={{ margin: '30px' }}>{message}</h3>
      </DialogContent>
    </SlideUpDialog>
  )
}
