import { Switch, Box } from '@mui/material'
import { PrimaryButton, PersistantFilterDiv } from '@wavetronix/common-components'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMemo, useState, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import UpdatesFilterDrawer, { DEFAULT_UPDATES_FILTER, filterUpdates } from '../../drawers/UpdatesFilterDrawer'
import UpdatesApi from '../../../api/UpdatesApi'
import GroupsUpdatesTable from '../../gridComponents/GroupsUpdatesTable'
import { getDeviceName } from '../../../utils/PermissionsMap.js'
import { listHaveDifferences } from '../../../utils/arrayUtils.js'

export default function EditGroupUpdates({
  groupData,
  group,
  setGroup,
  instance,
  accounts,
  isUploading,
  setIsUploading,
  isReadOnly,
  updateGroupChangeType,
  generateListDiff,
  hasChanges,
  setHasChanges
}) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen
  const [updatesFilter, setUpdatesFilter] = useState(DEFAULT_UPDATES_FILTER)

  const { data: updatesData, isLoading: updatesIsLoading } = useQuery({
    queryKey: ['updates'],
    queryFn: async () => await UpdatesApi.getUpdates(instance, accounts)
  })

  useEffect(() => {
    if (group && groupData) {
      let hasDiff = listHaveDifferences(group.updates, groupData.updates)
      setHasChanges(hasDiff)
    }
  }, [group, groupData, setHasChanges])

  const filteredUpdates = useMemo(() => {
    if (updatesData && updatesFilter) {
      return filterUpdates(updatesFilter, updatesData)
    }
  }, [updatesData, updatesFilter])

  const updatesMap = useMemo(() => {
    if (updatesData) {
      return updatesData.reduce(
        (map, obj) => {
          map[obj.id] = { ...obj, name: `${getDeviceName(obj.baseProductId)}_${obj.version}` }
          return map
        },
        [updatesData]
      )
    }
  }, [updatesData])

  async function onSwitchChange(e, update) {
    setIsUploading(true)
    if (e.target.checked) {
      setGroup(g => ({ ...g, updates: [...g.updates, update.id] }))
    } else {
      setGroup(g => ({ ...g, updates: g.updates.filter(uId => uId !== update.id) }))
    }
    setIsUploading(false)
  }

  const generateNotes = ids => {
    let notes = []

    if (hasChanges) {
      generateListDiff(notes, 'updates', 'Updates', updatesMap, 'name')
    }

    return notes
  }

  return (
    <Box sx={{ display: 'block' }}>
      <PersistantFilterDiv
        defaultOpen={largeScreenFeaturesActive}
        resetFilter={() => setUpdatesFilter(DEFAULT_UPDATES_FILTER)}
        drawer={<UpdatesFilterDrawer setFilter={setUpdatesFilter} filter={updatesFilter} />}
        page={
          <>
            <Box sx={{ float: 'right', width: '100%' }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <PrimaryButton
                style={{ width: '200px', float: 'right', marginBottom: '15px' }}
                disabled={isUploading || isReadOnly || hasChanges ? !hasChanges : true}
                onClick={async () => {
                  setIsUploading(true)
                  await updateGroupChangeType('updates', group.updates, generateNotes(group.updates))
                  setIsUploading(false)
                }}
              >
                Save
              </PrimaryButton>
            </Box>
            <Box sx={{ width: '100%' }}>
              <GroupsUpdatesTable
                updates={filteredUpdates ? filteredUpdates : []}
                isLoading={updatesIsLoading}
                overridesComparator={(a, b) => {
                  let AInGroup = group.updates ? group.updates.includes(a) : false
                  let BInGroup = group.updates ? group.updates.includes(b) : false
                  if (AInGroup === true && BInGroup === false) {
                    return -1
                  } else if (AInGroup === false && BInGroup === true) {
                    return 1
                  } else {
                    return 0
                  }
                }}
                renderSwitch={update => (
                  <Switch
                    checked={group.updates ? group.updates.includes(update.id) : false}
                    color={group.updates && group.updates.includes(update.id) ? 'primary' : 'secondary'}
                    onChange={e => onSwitchChange(e, update)}
                    disabled={isUploading || isReadOnly}
                  />
                )}
              />
            </Box>
          </>
        }
      />
    </Box>
  )
}
