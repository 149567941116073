import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class MasterFeaturesApi {
  getMasterFeatureList = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)

    return await axios
      .get(`${env.urls.updatesURL}/api/v1/masterfeatures`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
  }

  getBaseProducts = async (msalInstance, accounts) =>
    await this.getMasterFeatureList(msalInstance, accounts).then(masterFeatureList => masterFeatureList.licenseBaseIdList)
}

const getInstance = () => {
  if (instance == null) {
    instance = new MasterFeaturesApi()
  }
  return instance
}

export default getInstance()
