import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class UpdatesApi {
  getUpdates = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .get(`${env.urls.updatesURL}/api/v1/updates`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  removeUpdateOverridesByUserId = async (msalInstance, accounts, userId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .delete(`${env.urls.updatesURL}/api/v1/updates/overrides/removeAll/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new UpdatesApi()
  }
  return instance
}

export default getInstance()
