import { useState, useEffect } from 'react'
import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { SnackbarVariants, CustomDataGrid } from '@wavetronix/common-components'
import { Switch } from '@mui/material'
import GatekeeperApi from '../../../api/GatekeeperApi'
import { useSnackbar } from 'notistack'

export default function CountriesList({ user, widgetsDisabled, setWidgetsDisabled }) {
  const { instance, accounts } = useMsal()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [regions, setRegions] = useState([])
  const [pageSize, setPageSize] = useState(100)
  const [regionsMap, setRegionsMap] = useState({})

  const {
    data: regionData,
    refetch: regionRefetch,
    isLoading: regionIsLoading
  } = useQuery({ queryKey: ['regions'], queryFn: async () => await GatekeeperApi.getRegions(instance, accounts) })

  useEffect(() => {
    if (regionData && user) {
      setRegions(regionData)
      regionData.forEach(region => {
        setRegionsMap(rm => ({
          ...rm,
          [region.id]: region.onCreatedUsers && user.id && region.onCreatedUsers.includes(user.id)
        }))
      })
    }
  }, [regionData, user])

  async function addOverrides(country) {
    let key = enqueueSnackbar('Adding country...', SnackbarVariants.LOADING)

    await GatekeeperApi.addUserToRegion(instance, accounts, user.id, country)
      .then(async () => {
        closeSnackbar(key)
        enqueueSnackbar('Successfully added country', SnackbarVariants.SUCCESS)
        await regionRefetch()
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
      })
  }

  async function removeOverrides(country) {
    let key = enqueueSnackbar('Removing country...', SnackbarVariants.LOADING)
    await GatekeeperApi.removeUserFromRegion(instance, accounts, user.id, country)
      .then(async () => {
        closeSnackbar(key)
        enqueueSnackbar('Successfully removed country', SnackbarVariants.SUCCESS)
        await regionRefetch()
      })
      .catch(() => {
        closeSnackbar(key)
        enqueueSnackbar('An error occurred', SnackbarVariants.ERROR)
      })
  }

  async function onCountrySwitchChange(e, country) {
    setWidgetsDisabled(true)
    if (e.target.checked) {
      await addOverrides(country)
    } else {
      await removeOverrides(country)
    }
    setWidgetsDisabled(false)
  }

  const columns = [
    {
      field: 'override',
      headerName: 'Add',
      flex: 0.25,
      disableColumnMenu: true,
      renderCell: data => (
        <Switch
          id={`${data.row.id}Switch`}
          checked={regionsMap && regionsMap[data.row.id] ? regionsMap[data.row.id] : false}
          color={regionsMap && regionsMap[data.row.id] && regionsMap[data.row.id] ? 'primary' : 'secondary'}
          onChange={e => onCountrySwitchChange(e, data.row.id)}
          disabled={widgetsDisabled}
        />
      )
    },
    {
      field: 'name',
      headerName: 'Country/Region',
      flex: 1,
      disableColumnMenu: true,
      sortable: true
    }
  ]

  return (
    <>
      <div style={{ height: 'block', width: '100%' }}>
        <CustomDataGrid
          rows={regions}
          autoHeight
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={p => setPageSize(p)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          disableSelectionOnClick
          loading={regionIsLoading}
        />
      </div>
    </>
  )
}
