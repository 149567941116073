export const localEnv = {
  wavetronix: {
    windowURL: 'nexus.wtxdev.com',
    runMode: 'dev',
    clientId: 'a48c7ddf-dc79-44f5-bd0d-5fec3f210ee5',
    urls: {
      // gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com',
      gatekeeperURL: 'http://localhost:5001',
      updatesURL: 'https://wt-updatesmanagement-rest.wtxdev.com',
      // updatesURL: 'http://localhost:5002',
      documentsURL: 'https://wtx-mydocuments-rest.wtxdev.com'
      // documentsURL: 'http://localhost:5000'
    },
    basicAuthentication: {}
  },
  islandradar: {
    windowURL: 'nexus.islandradardev.com',
    runMode: 'dev',
    clientId: 'a48c7ddf-dc79-44f5-bd0d-5fec3f210ee5',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com/islandradar',
      // gatekeeperURL: 'http://localhost:5001/islandradar',
      updatesURL: 'https://wt-updatesmanagement-rest.wtxdev.com/islandradar',
      // updatesURL: 'http://localhost:5002/islandradar',
      documentsURL: 'https://wtx-mydocuments-rest.wtxdev.com/islandradar'
      // documentsURL: 'http://localhost:5000/islandradar'
    },
    basicAuthentication: {}
  },
  fathom: {
    windowURL: 'nexus.fathomradar.com',
    runMode: 'prod',
    clientId: 'e6657d5e-4be6-4f95-857b-0b15ecc34be5',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wavetronix.com/fathom',
      // gatekeeperURL: 'http://localhost:5001/fathom',
      updatesURL: 'https://wt-updatesmanagement-rest.wavetronix.com/fathom',
      // updatesURL: 'http://localhost:5002/fathom',
      documentsURL: 'https://wtx-mydocuments-rest.wavetronix.com/fathom'
      // documentsURL: 'http://localhost:5000/fathom'
    },
    basicAuthentication: {}
  }
}
