import { CenteredDiv, CustomSelect } from '@wavetronix/common-components'
import { useState, Suspense, useEffect } from 'react'
import { CircularProgress, Grid } from '@mui/material'
import CountriesList from './notificationComponents/CountriesList'

const NOTIFICATION_TYPE_OPTIONS = [
  {
    notification: 'Nexus User Created - By Region',
    description: 'User will recieve an email notifying them if a user signs up under a specific region.',
    approvedUsers: {
      type: ['InternalUser'],
      roles: ['Sales Admin', 'Gatekeeper Admin', 'Expanse Admin', 'Sales Team Member']
    }
  }
]

const hasNotificationPermission = (user, approvals) => {
  var result = false
  if (approvals.type && (approvals.type.length === 0 || approvals.type.includes(user.type))) {
    result = true
  } else if (approvals.roles && user.roles && approvals.roles.map(role => user.roles.includes(role)).includes(true)) {
    result = true
  }
  return result
}

export default function UserEmailNotificationsTable({ user, widgetsDisabled, setWidgetsDisabled }) {
  const [notificationType, setNotificationType] = useState('')
  const [notificationOptions, setNotificationOptions] = useState({})

  useEffect(() => {
    var optionMap = {}
    if (user) {
      for (var option of NOTIFICATION_TYPE_OPTIONS) {
        if (hasNotificationPermission(user, option.approvedUsers) === true) {
          optionMap[option.notification] = option
        }
      }
      setNotificationType('')
    }
    setNotificationOptions(optionMap)
  }, [user])
  return (
    <>
      <Grid container>
        <Grid item lg={5} md={12}>
          <CustomSelect
            id='notificationTypeSelect'
            style={{ marginTop: '15px', width: '400px' }}
            label={'Notification Type'}
            value={notificationType}
            onChange={e => {
              setNotificationType(e.target.value)
            }}
            options={Object.keys(notificationOptions)}
            disabled={widgetsDisabled}
          />
        </Grid>
        {notificationType && notificationType !== '' ? (
          <Grid item lg={12}>
            <div style={{ margin: '15px 15px 15px 0px' }}>
              {notificationOptions[notificationType] ? notificationOptions[notificationType].description : ''}
            </div>
          </Grid>
        ) : (
          <span />
        )}
      </Grid>
      <div style={{ height: 'block', width: '100%' }}>
        <Suspense
          fallback={
            <CenteredDiv>
              <CircularProgress />
            </CenteredDiv>
          }
        >
          {
            {
              'Nexus User Created - By Region': (
                <CountriesList user={user} widgetsDisabled={widgetsDisabled} setWidgetsDisabled={setWidgetsDisabled} />
              ),
              // 'Texas Instraments - Part Available': <div>Texas Instraments - Part Available</div>,
              '': (
                <div style={{ margin: '15px 15px 15px 0px' }}>
                  Please select a notification type. If there are no options available then this user doesn't have the correct
                  permissions for our current notifications. Please speak with the Gojira team if there is one you want to add.
                </div>
              )
            }[notificationType]
          }
        </Suspense>
      </div>
    </>
  )
}
