import { TextField } from '@mui/material'

export const DEFAULT_GROUP_FILTER = {
  groupName: '',
  groupDescription: ''
}

export default function GroupFilterDrawer({ filter, setFilter }) {
  return (
    <>
      <TextField
        id='groupNameTextField'
        label={'Group Name'}
        size='small'
        variant='outlined'
        style={{ width: '100%' }}
        onChange={e => setFilter(s => ({ ...s, groupName: e.target.value }))}
        value={filter.groupName}
      />
      <TextField
        id='groupDescriptionTextField'
        label={'Description'}
        size='small'
        variant='outlined'
        style={{ width: '100%', marginTop: '15px' }}
        onChange={e => setFilter(s => ({ ...s, groupDescription: e.target.value }))}
        value={filter.groupDescription}
      />
    </>
  )
}
