import {
  CenteredDiv,
  CustomDataGrid,
  ErrorMessage,
  RegularButton,
  PersistantFilterDiv,
  selectAccount,
  useWtxLocalization,
  HasAccess
} from '@wavetronix/common-components'
import { useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import { useNavigate } from 'react-router'
import GroupFilterDrawer, { DEFAULT_GROUP_FILTER } from '../drawers/GroupFilterDrawer'
import AddGroupModal from '../modals/AddGroupModal'
import { Divider, Tooltip } from '@mui/material'
import GatekeeperApi from '../../api/GatekeeperApi'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import InfoIcon from '@mui/icons-material/Info'
import { env } from '../../index.js'

const nullCheck = s => (s ? s : '')

const filterGroups = (filter, groups) =>
  groups.filter(
    r =>
      nullCheck(r.groupName).toLowerCase().includes(filter.groupName.toLowerCase()) &&
      nullCheck(r.groupDescription).toLowerCase().includes(filter.groupDescription.toLowerCase())
  )

export default function GroupsPage({ dealerAdmin, userId, ...props }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen
  let localizedStrings = useWtxLocalization()

  const { instance, accounts } = useMsal()

  const account = selectAccount(accounts, env)
  const [filter, setFilter] = useState(DEFAULT_GROUP_FILTER)
  const [pageSize, setPageSize] = useState(100)
  const [addGroupDrawerState, setAddGroupDrawerState] = useState(false)
  const [dealerGroup, setDealerGroup] = useState(false)
  const navigate = useNavigate()
  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['groups'],
    queryFn: async () => await GatekeeperApi.getGroups(instance, accounts)
  })

  const filteredGroups = useMemo(() => {
    if (filter && data) {
      let filterResult =
        dealerAdmin === true
          ? filterGroups(filter, data)
          : filterGroups(
              filter,
              data.filter(group => group.dealerGroup === true && group.admins.includes(userId))
            )

      return filterResult
    }
  }, [dealerAdmin, filter, data, userId])

  document.title = 'Nexus Management'

  const columns = [
    {
      field: 'groupName',
      headerName: 'Name',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: true
    },
    {
      field: 'groupDescription',
      headerName: 'Description',
      flex: 2,
      disableColumnMenu: true,
      sortable: true
    }
  ]

  if (!account) {
    return (
      <CenteredDiv>
        <h3>403 - {localizedStrings.invalidAccount}</h3>
      </CenteredDiv>
    )
  }

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <PersistantFilterDiv
      defaultOpen={largeScreenFeaturesActive}
      resetFilter={() => setFilter(DEFAULT_GROUP_FILTER)}
      drawer={<GroupFilterDrawer setFilter={setFilter} filter={filter} />}
      page={
        <>
          <HasAccess env={env} allowedRoles={['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin']}>
            <div style={{ width: '345px' }}>
              <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                <RegularButton
                  id='addGroupStateModalButton'
                  onClick={() => {
                    setDealerGroup(false)
                    setAddGroupDrawerState(true)
                  }}
                  style={{ margin: '16px' }}
                >
                  Add Group
                </RegularButton>
                <RegularButton
                  id='addDealerGroupStateModalButton'
                  onClick={() => {
                    setDealerGroup(true)
                    setAddGroupDrawerState(true)
                  }}
                  style={{ margin: '16px', marginRight: '5px' }}
                >
                  Add Dealer Group
                </RegularButton>
                <Tooltip title="Dealer groups allow members to view Nexus users in their region and control those users' access to documents.">
                  <InfoIcon></InfoIcon>
                </Tooltip>
              </div>
            </div>
          </HasAccess>

          <Divider style={{ height: '0px', borderColor: 'transparent' }} />
          {filterGroups && filterGroups.length === 0 ? (
            <CenteredDiv>No groups available.</CenteredDiv>
          ) : (
            <CustomDataGrid
              rows={filteredGroups ? filteredGroups : []}
              autoHeight
              columns={columns}
              pageSize={pageSize}
              onPageSizeChange={p => setPageSize(p)}
              rowsPerPageOptions={[10, 20, 50, 100]}
              disableSelectionOnClick
              loading={isLoading}
              style={{ margin: '0px 0px 0px 24px' }}
              onRowClick={e => {
                let queryString = new URLSearchParams({
                  id: e.row.id,
                  isDealerGroup: e.row.dealerGroup
                }).toString()
                navigate(`/group/overview?${queryString}`)
              }}
              cursor='pointer'
            />
          )}
          <AddGroupModal
            open={addGroupDrawerState}
            onClose={() => {
              setAddGroupDrawerState(false)
            }}
            refetch={refetch}
            dealerGroup={dealerGroup}
          />
        </>
      }
    />
  )
}
