const en = {
  givenName: 'First Name',
  surname: 'Last Name',
  company: 'Company',
  email: 'Email',
  filter: 'Filter',
  groups: 'Groups',
  clear: 'Clear',
  firstName: 'First Name',
  lastName: 'Last Name',
  region: 'Location',
  name: 'Name',
  apply: 'Apply',
  cancel: 'Cancel',
  close: 'Close',
  back: 'Back',
  manageUser: 'Manage User',
  save: 'Save',
  'My Software': 'My Software',
  'My Licenses': 'My Licenses',
  'My Documents': 'My Documents',
  noAccess: "You currently don't have access to any sites. Please contact a company representative.",
  snackbar: {
    saving: 'Saving...',
    savedRoles: 'Successfully saved updated role',
    errorSaving: 'Error while saving role'
  },
  description: 'Description',
  accessLevel: 'Access Level',
  associatedProducts: 'Associated Products',
  documentType: 'Document Type',
  products: 'Products',
  nexusPortal: 'PORTAL',
  nexusSignIn: 'Sign into Nexus',
  signIn: 'Sign in',
  invalidAccount: 'Error: Trouble validating account for this environment'
}
export { en }
