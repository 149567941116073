export const RELEASE_PHASE_MAP = {
  General: 0,
  Beta: 1,
  BetaCandidate: 2,
  Engineering: 3,
  Unreleased: 4
}

export function removeWhiteSpace(word) {
  if (word) {
    return word.replace(/\s+/g, '')
  }
  return word
}

export function insertWhiteSpace(word) {
  if (word) {
    return word.replace(/([a-z])([A-Z])/g, '$1 $2')
  }
  return word
}
