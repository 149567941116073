import { CssBaseline, Box } from '@mui/material'
import { MenuAppBar, RoleCheck, HasAccess, Spinner, NotFoundPage } from '@wavetronix/common-components'
import React, { useEffect, useState, useRef } from 'react'
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom'
import { env } from '../index.js'
import LandingPage from './pages/LandingPage'
import UsersPage from './pages/UsersPage'
import RolesPage from './pages/RolesPage'
import GroupsPage from './pages/GroupsPage'
import PageNavbar, { TAB_OPTIONS } from './PageNavbar'
import version from '../env/version.json'
import { useMsal } from '@azure/msal-react'
import GatekeeperApi from '../api/GatekeeperApi.js'
import GroupPageWrapper from './pages/EditGroup/GroupPageWrapper'

const classes = {
  paper: {
    width: 300
  },
  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  },
  filterTitle: {
    fontFamily: 'Klavika',
    fontSize: 22
  },
  title: {
    fontFamily: 'Klavika',
    fontSize: 26
  },
  inlineTitle: {
    fontFamily: 'Klavika',
    fontSize: '26px',
    display: 'inline'
  }
}

export default function MainPage() {
  const [state, setState] = useState('groups')

  const { instance, accounts } = useMsal()
  const [loaded, setLoaded] = useState(false)
  const me = useRef({})
  useEffect(() => {
    ;(async () => {
      me.current = await GatekeeperApi.getMe(instance, accounts).then(res => {
        setLoaded(true)
        return res
      })
    })()
  }, [instance, accounts])

  useEffect(() => {
    const validOptions = TAB_OPTIONS.map(option => option.value)
    const pathname = window.location.pathname.substring(1)
    setState(validOptions.includes(pathname) ? pathname : 'groups')
  }, [])

  return loaded ? (
    <>
      <CssBaseline />
      <MenuAppBar env={env} fromCompany={env.basicAuthentication.fromCompany} />
      <div className='content'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route
              element={
                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <PageNavbar value={state} onChangeCallback={id => setState(id)} accounts={accounts} />
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    <Outlet />
                  </Box>
                </Box>
              }
            >
              <Route
                path='/users'
                element={
                  <div style={{ display: 'flex' }}>
                    <RoleCheck allowedRoles={['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member']} env={env}>
                      <div style={{ width: '100%' }}>
                        <UsersPage styles={classes} />
                      </div>
                    </RoleCheck>
                  </div>
                }
              />
              <Route
                path='/roles'
                element={
                  <RoleCheck allowedRoles={['Gatekeeper Admin']} env={env}>
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '100%' }}>
                        <RolesPage styles={classes} />
                      </div>
                    </div>
                  </RoleCheck>
                }
              />
              <Route
                path='/groups'
                element={
                  <div style={{ display: 'flex' }}>
                    <RoleCheck
                      allowedRoles={['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member', 'Dealer']}
                      env={env}
                    >
                      <HasAccess
                        env={env}
                        allowedRoles={['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member']}
                        unauthorizedControl={
                          <div style={{ width: '100%' }}>
                            <GroupsPage dealerAdmin={false} userId={me ? me.current.id : ''} styles={classes} />
                          </div>
                        }
                      >
                        <div style={{ width: '100%' }}>
                          <GroupsPage dealerAdmin={true} styles={classes} />
                        </div>
                      </HasAccess>
                    </RoleCheck>
                  </div>
                }
              />
            </Route>

            <Route path='/group/*' element={<GroupPageWrapper />} />
            <Route path='/version' element={<div>{version.version}</div>} />
            {/* This wildcard route needs to stay at the bottom */}
            <Route path='/*' element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  ) : (
    <Spinner></Spinner>
  )
}
