import { useMsal } from '@azure/msal-react'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import InfoIcon from '@mui/icons-material/Info'
import { Switch, TextField, Tooltip } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import {
  CenteredDiv,
  CustomAccordion,
  PrimaryButton,
  RegularButton,
  SlideUpDialog,
  SnackbarVariants,
  WtxColors,
  selectAccount
} from '@wavetronix/common-components'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import GatekeeperApi from '../../api/GatekeeperApi'
import { env } from '../../index.js'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps'
const classes = {
  fileMetadata: {
    width: '90%',
    marginTop: '15px'
  }
}

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json'

export const PERMISSABLE_ADMINS = ['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member']
export const INTERNATIONAL_REGIONS = [
  'Europe',
  'Asia',
  'Africa',
  'South America',
  'Canada',
  'Australia',
  'American Samoa',
  'Guam',
  'Northern Mariana Islands',
  'Puerto Rico',
  'Trust Territories',
  'Virgin Islands',
  'District of Columbia'
]

export const DEFAULT_GROUP = {
  id: '',
  groupName: '',
  groupDescription: '',
  roles: [],
  updates: [],
  documents: [],
  packages: [],
  dealerGroup: false,
  dealerStates: [],
  admins: [],
  updatesAccessLevel: {
    wavetronix: 'General',
    islandradar: 'General',
    fathomradar: 'General'
  },
  documentsAccessLevel: {
    wavetronix: 'Basic',
    islandradar: 'Basic',
    fathomradar: 'Basic'
  },
  permissableAdmins: ['Gatekeeper Admin'],
  fromCompany: '',
  history: []
}

export default function AddGroupModal({ open, onClose, refetch, dealerGroup }) {
  const { instance, accounts } = useMsal()
  const [group, setGroup] = useState({ ...DEFAULT_GROUP })
  const { enqueueSnackbar } = useSnackbar()
  const [isUploading, setIsUploading] = useState(false)

  useEffect(() => {
    if (open === true) {
      let currentAccount = selectAccount(accounts, env)
      let addRoles = []
      if (currentAccount && currentAccount.idTokenClaims && currentAccount.idTokenClaims.roles) {
        if (currentAccount.idTokenClaims.roles.includes('Expanse Admin')) {
          addRoles.push('Expanse Admin')
        }

        if (currentAccount.idTokenClaims.roles.includes('Sales Admin')) {
          addRoles.push('Sales Admin')
        }

        setGroup(g => ({ ...g, permissableAdmins: [...g.permissableAdmins, ...addRoles] }))
      }
    }
  }, [open, accounts])

  function closeModal() {
    refetch()
    onClose()
    setGroup(DEFAULT_GROUP)
  }

  function createGroup() {
    return {
      ...group,
      groupName: group.groupName,
      groupDescription: dealerGroup
        ? (group.groupName ? group.groupName : '<DealerName>') +
          ' dealer group. Admins can manage access for users in the following regions:\n' +
          (group.dealerStates.length > 0 ? group.dealerStates.sort().join(', ') : 'None specified')
        : group.groupDescription,
      roles: group.roles,
      updates: group.updates,
      documents: group.documents,
      packages: group.packages,
      permissableAdmins: dealerGroup ? [...group.permissableAdmins, 'Sales Team Member'] : group.permissableAdmins,
      dealerGroup: dealerGroup,
      dealerStates: group.dealerStates
    }
  }

  const addGroup = async group => {
    await GatekeeperApi.createGroup(instance, accounts, group).then(
      _ => {
        enqueueSnackbar('Create group success', SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`Failed to create group`, SnackbarVariants.ERROR)
      }
    )
  }

  const onCheckChangeAdminPermission = async (e, role) => {
    let checked = e.target.checked
    if (checked) {
      setGroup(g => ({ ...g, permissableAdmins: [...group.permissableAdmins, role] }))
    } else {
      setGroup(g => ({ ...g, permissableAdmins: group.permissableAdmins.filter(r => r !== role) }))
    }
  }

  const onCheckChangeDealerStates = async (e, state) => {
    if (!group.dealerStates.includes(state)) {
      setGroup(g => ({ ...g, dealerStates: [...group.dealerStates, state] }))
    } else {
      setGroup(g => ({ ...g, dealerStates: group.dealerStates.filter(s => s !== state) }))
    }
  }

  return (
    <SlideUpDialog
      id='addGroupModal'
      maxWidth={'sm'}
      open={open}
      onClose={closeModal}
      title={<h3 style={{ margin: 0 }}>Add Group</h3>}
      actions={
        <div>
          <RegularButton id='addGroupCancelButton' onClick={closeModal} disabled={isUploading} style={{ marginRight: 15 }}>
            &nbsp;Cancel
          </RegularButton>
          <PrimaryButton
            id='addGroupCreateButton'
            disabled={!(dealerGroup ? group.groupName !== '' && group.dealerStates.length > 0 : group.groupName !== '')}
            onClick={async () => {
              setIsUploading(true)
              let g = createGroup()
              await addGroup(g)
              setIsUploading(false)
              closeModal()
            }}
          >
            &nbsp;Create
          </PrimaryButton>
        </div>
      }
    >
      {dealerGroup ? (
        <DialogContent>
          <CenteredDiv>
            <TextField
              id='groupNameTextField'
              disabled={isUploading}
              label={'Dealer Name'}
              variant='outlined'
              style={classes.fileMetadata}
              value={group.groupName}
              onChange={e => setGroup(g => ({ ...g, groupName: e.target.value }))}
            />
          </CenteredDiv>
          <CenteredDiv>
            <div style={{ width: '90%', marginTop: '15px' }}>
              <Tooltip title="Dealer groups' descriptions will be automatically populated based on other fields.">
                <InfoIcon fontSize='small' />
              </Tooltip>
              <TextField
                id='groupDescriptionTextField'
                disabled={true}
                label={'Description'}
                value={
                  (group.groupName ? group.groupName : '<DealerName>') +
                  ' dealer group. Admins can manage access for users in the following regions:\n' +
                  (group.dealerStates.length > 0 ? group.dealerStates.sort().join(', ') : 'None specified')
                }
                variant='outlined'
                sx={{ width: '100%' }}
                multiline
                minRows={4}
              />
            </div>
          </CenteredDiv>
          <CenteredDiv>
            <div style={classes.fileMetadata}>
              <CustomAccordion
                defaultExpanded
                title={<>States</>}
                children={
                  <div>
                    <ComposableMap projection='geoAlbersUsa'>
                      <Geographies geography={geoUrl}>
                        {({ geographies }) =>
                          geographies.map(geo => {
                            return (
                              <Geography
                                key={geo.rsmKey}
                                geography={geo}
                                style={{
                                  hover: { fill: '#5af', outline: 'none' },
                                  pressed: { fill: '#49e', outline: 'none' },
                                  default: { outline: 'none' }
                                }}
                                fill={group.dealerStates.includes(geo.properties.name) ? WtxColors.IQ_BLUE : WtxColors.CONCRETE}
                                stroke={'white'}
                                onClick={e => {
                                  onCheckChangeDealerStates(e, geo.properties.name)
                                }}
                              />
                            )
                          })
                        }
                      </Geographies>
                    </ComposableMap>
                    <CenteredDiv>
                      <CustomAccordion
                        sx={{ width: '80%', margin: '8px' }}
                        title={
                          <div style={{ display: 'flex' }}>
                            International Regions
                            <Tooltip title='Regions outside of the United States' sx={{ marginLeft: '5px', marginTop: '2px' }}>
                              <InfoIcon fontSize='small' />
                            </Tooltip>
                          </div>
                        }
                        children={
                          <>
                            {INTERNATIONAL_REGIONS.map(reg => {
                              return (
                                <div style={{ display: 'flex', marginLeft: '10px' }} key={reg}>
                                  <Switch
                                    id={`${reg}Switch`}
                                    checked={group.dealerStates.includes(reg)}
                                    color={group.dealerStates.includes(reg) ? 'primary' : 'secondary'}
                                    onChange={e => onCheckChangeDealerStates(e, reg)}
                                    disabled={isUploading}
                                  />
                                  <div style={{ marginTop: '8px' }}>{reg}</div>
                                </div>
                              )
                            })}
                          </>
                        }
                      />
                    </CenteredDiv>
                  </div>
                }
              ></CustomAccordion>
            </div>
          </CenteredDiv>
        </DialogContent>
      ) : (
        <DialogContent>
          <CenteredDiv>
            <TextField
              id='groupNameTextField'
              disabled={isUploading}
              label={'Group Name'}
              variant='outlined'
              style={classes.fileMetadata}
              value={group.groupName}
              onChange={e => setGroup(g => ({ ...g, groupName: e.target.value }))}
            />
          </CenteredDiv>
          <CenteredDiv>
            <TextField
              id='groupDescriptionTextField'
              disabled={isUploading}
              label={'Description'}
              value={group.groupDescription}
              variant='outlined'
              sx={classes.fileMetadata}
              multiline
              minRows={4}
              onChange={e => setGroup(g => ({ ...g, groupDescription: e.target.value }))}
            />
          </CenteredDiv>
          <CenteredDiv>
            <div style={classes.fileMetadata}>
              <CustomAccordion
                defaultExpanded
                title={
                  <div style={{ display: 'flex' }}>
                    Administrators
                    <Tooltip
                      title='Defines which admins can edit permissions and roles, assign users, and add overrides to a group'
                      sx={{ marginLeft: '5px', marginTop: '2px' }}
                    >
                      <HelpOutlineIcon fontSize='small' />
                    </Tooltip>
                  </div>
                }
                children={
                  <>
                    {PERMISSABLE_ADMINS.map(role => {
                      return (
                        <div style={{ display: 'flex', marginLeft: '10px' }} key={role}>
                          <Switch
                            id={`${role}AdminSwitch`}
                            checked={group.permissableAdmins.includes(role)}
                            color={group.permissableAdmins.includes(role) ? 'primary' : 'secondary'}
                            onChange={e => onCheckChangeAdminPermission(e, role)}
                            disabled={role === 'Gatekeeper Admin' ? true : isUploading}
                          />
                          <div style={{ marginTop: '8px' }}>{role}</div>
                        </div>
                      )
                    })}
                  </>
                }
              />
            </div>
          </CenteredDiv>
        </DialogContent>
      )}
    </SlideUpDialog>
  )
}
