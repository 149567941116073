import { useMsal } from '@azure/msal-react'
import { CustomAccordion, ListSelector } from '@wavetronix/common-components'
import { useQuery } from '@tanstack/react-query'
import GatekeeperApi from '../../api/GatekeeperApi'

export default function AdvancedRolesSelector({ selectedRoles, onChange, title = 'Roles', disabled = false }) {
  const { instance, accounts } = useMsal()
  const { data } = useQuery({ queryKey: ['roles'], queryFn: async () => await GatekeeperApi.getRoles(instance, accounts) })

  return (
    <>
      {data ? (
        <CustomAccordion
          title={title}
          detailStyle={{ marginTop: '2px' }}
          disabled={disabled}
          children={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <ListSelector
                style={{ flexGrow: 1, minHeight: '200px', maxHeight: 'calc(100vh - 200px)' }}
                selectedOptions={selectedRoles}
                options={[...(data ? data.map(role => ({ id: role.id, value: role.id })) : [])]}
                onChange={onChange}
              />
            </div>
          }
        />
      ) : null}
    </>
  )
}
