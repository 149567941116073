import { CustomDataGrid, useWtxLocalization } from '@wavetronix/common-components'
import dayjs from 'dayjs'
import { getCountryHash } from '../../utils/countryUtil.js'
import { useMemo } from 'react'
export default function GroupsUserAdminTable({ usersData, renderSwitch, overridesComparator }) {
  const localizedStrings = useWtxLocalization()

  const dealerOnlyUsers = useMemo(() => {
    if (usersData) {
      return usersData.filter(user => user.roles.includes('Dealer'))
    }
  }, [usersData])

  const columns = [
    {
      field: 'override',
      headerName: '',
      flex: 0.75,
      sortComparator: overridesComparator,
      filterable: false,
      renderCell: value => renderSwitch(value.row)
    },
    {
      field: 'givenName',
      headerName: localizedStrings.givenName,
      flex: 1
    },
    {
      field: 'surname',
      headerName: localizedStrings.surname,
      flex: 1
    },
    {
      field: 'company',
      headerName: localizedStrings.company,
      flex: 1,
      hide: true
    },
    {
      field: 'email',
      headerName: localizedStrings.email,
      flex: 1
    },
    {
      field: 'createdDate',
      headerName: 'Date Created',
      flex: 1,
      hide: true,
      valueGetter: u => dayjs(u.row.createdDate).format('MM/DD/YYYY')
    },
    {
      field: 'country',
      headerName: localizedStrings.region,
      flex: 0.5,
      hide: true,
      valueGetter: u => getCountryHash(u.row.country)
    }
  ]
  return (
    <CustomDataGrid
      rows={dealerOnlyUsers ? dealerOnlyUsers : []}
      autoHeight
      columns={columns}
      disableSelectionOnClick
      initialState={{
        sorting: {
          sortModel: [
            {
              field: 'override',
              sort: 'asc'
            }
          ]
        }
      }}
    />
  )
}
