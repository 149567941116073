import { CustomAccordion } from '@wavetronix/common-components'
import { Chip } from '@mui/material'

export default function AdvancedRolesViewer({ roles, title = 'Roles', disabled }) {
  return (
    <>
      {roles ? (
        <CustomAccordion
          title={title}
          defaultExpanded
          detailStyle={{ marginTop: '2px' }}
          children={
            <div style={{ display: 'flex', margin: '5px 15px 15px 15px' }}>
              {roles.map(role => (
                <Chip label={role} sx={{ margin: '5px' }} />
              ))}
            </div>
          }
        />
      ) : null}
    </>
  )
}
