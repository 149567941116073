import { Box, Tooltip, Switch } from '@mui/material'
import { CustomAccordion, WtxColors } from '@wavetronix/common-components'
import { ComposableMap, Geographies, Geography } from 'react-simple-maps'
import InfoIcon from '@mui/icons-material/Info.js'
import { INTERNATIONAL_REGIONS } from '../../components/modals/AddGroupModal'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json'

export default function GroupsRegionsMap({ group, setGroup, isUploading, isReadOnly, groupHasChanges }) {
  const onCheckChangeDealerStates = async (e, state) => {
    if (!group.dealerStates.includes(state)) {
      setGroup(g => ({ ...g, dealerStates: [...group.dealerStates, state] }))
    } else {
      setGroup(g => ({ ...g, dealerStates: group.dealerStates.filter(s => s !== state) }))
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <CustomAccordion
        defaultExpanded
        title={
          <div style={{ display: 'flex' }}>
            States
            <ChangeCircleIcon
              sx={{
                color: WtxColors.IQ_BLUE,
                display: groupHasChanges.statesHasChanges ? 'default' : 'none',
                marginLeft: '5px'
              }}
            />
          </div>
        }
        children={
          <div>
            <ComposableMap projection='geoAlbersUsa'>
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map(geo => {
                    return (
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        style={{
                          hover: !(isUploading || isReadOnly) ? { fill: '#5af', outline: 'none' } : {},
                          pressed: !(isUploading || isReadOnly) ? { fill: '#49e', outline: 'none' } : {},
                          default: { outline: 'none' }
                        }}
                        fill={group.dealerStates.includes(geo.properties.name) ? WtxColors.IQ_BLUE : WtxColors.CONCRETE}
                        stroke={'white'}
                        onClick={e => {
                          if (!(isUploading || isReadOnly)) {
                            onCheckChangeDealerStates(e, geo.properties.name)
                          }
                        }}
                      />
                    )
                  })
                }
              </Geographies>
            </ComposableMap>
          </div>
        }
      />
      <CustomAccordion
        style={{ marginTop: '15px' }}
        title={
          <div style={{ display: 'flex' }}>
            International Regions
            <Tooltip title='Regions outside of the United States' sx={{ marginLeft: '5px', marginTop: '2px' }}>
              <InfoIcon fontSize='small' />
            </Tooltip>
            <ChangeCircleIcon
              sx={{
                color: WtxColors.IQ_BLUE,
                display: groupHasChanges.statesHasChanges ? 'default' : 'none',
                marginLeft: '5px'
              }}
            />
          </div>
        }
        children={
          <div style={{ marginTop: '15px' }}>
            {INTERNATIONAL_REGIONS.map(reg => {
              return (
                <div style={{ display: 'flex', marginLeft: '10px' }} key={reg}>
                  <Switch
                    checked={group.dealerStates ? group.dealerStates.includes(reg) : false}
                    color={group.dealerStates ? (group.dealerStates.includes(reg) ? 'primary' : 'secondary') : 'secondary'}
                    onChange={e => onCheckChangeDealerStates(e, reg)}
                    disabled={isUploading || isReadOnly}
                  />
                  <div style={{ marginTop: '8px' }}>{reg}</div>
                </div>
              )
            })}
          </div>
        }
      />
    </Box>
  )
}
