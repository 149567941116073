import * as React from 'react'
import { CenteredDiv, PrimaryButton, RegularButton, SnackbarVariants, SlideUpDialog } from '@wavetronix/common-components'
import DialogContent from '@mui/material/DialogContent'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState } from 'react'
import { useMsal } from '@azure/msal-react'
import { TextField } from '@mui/material'
import { useSnackbar } from 'notistack'
import GatekeeperApi from '../../api/GatekeeperApi'

const classes = {
  fileMetadata: {
    width: '80%',
    marginTop: '15px'
  }
}

export default function AddRoleModal({ open, onClose, refetch }) {
  const { instance, accounts } = useMsal()
  const [description, setDescription] = useState('')
  const [name, setName] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [isUploading, setIsUploading] = useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'))

  function closeModal() {
    refetch()
    onClose()
    setDescription('')
    setName('')
  }

  function createRole() {
    const newRole = {
      id: name,
      description: description
    }
    return newRole
  }

  const addRole = async role => {
    await GatekeeperApi.createRoleInfo(instance, accounts, role).then(
      _ => {
        enqueueSnackbar('Add role success', SnackbarVariants.SUCCESS)
      },
      error => {
        enqueueSnackbar(`Failed to add role`, SnackbarVariants.ERROR)
      }
    )
  }

  return (
    <SlideUpDialog
      id='addRoleModal'
      fullScreen={fullScreen}
      maxWidth={'sm'}
      open={open}
      onClose={closeModal}
      title={<h3 style={{ margin: 0 }}>Add Role</h3>}
      actions={
        <>
          <RegularButton id='addRoleCancelButton' onClick={closeModal} disabled={isUploading}>
            &nbsp;Cancel
          </RegularButton>
          <PrimaryButton
            id='addRoleCreateButton'
            style={{ marginRight: 15 }}
            disabled={!name || !description}
            onClick={async () => {
              setIsUploading(true)
              await addRole(createRole())
              setIsUploading(false)
              closeModal()
            }}
          >
            &nbsp;Create
          </PrimaryButton>
        </>
      }
    >
      <DialogContent>
        <CenteredDiv>
          <TextField
            id='roleNameTextField'
            disabled={isUploading}
            label={'Role Name'}
            variant='outlined'
            style={classes.fileMetadata}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </CenteredDiv>
        <CenteredDiv>
          <TextField
            id='roleDescriptionTextField'
            disabled={isUploading}
            label={'Description'}
            value={description}
            variant='outlined'
            sx={classes.fileMetadata}
            multiline
            minRows={4}
            onChange={e => setDescription(e.target.value)}
          />
        </CenteredDiv>
      </DialogContent>
    </SlideUpDialog>
  )
}
