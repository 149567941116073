import { useMsal } from '@azure/msal-react'
import { CustomAccordion, ListSelector } from '@wavetronix/common-components'
import { useQuery } from '@tanstack/react-query'
import GatekeeperApi from '../../api/GatekeeperApi'

export default function RolesSelector({ filter, setFilter, title = 'Roles', disabled = false }) {
  const { instance, accounts } = useMsal()
  const { data } = useQuery({ queryKey: ['roles'], queryFn: async () => await GatekeeperApi.getRoles(instance, accounts) })

  return (
    <>
      {data ? (
        <CustomAccordion
          title={title}
          style={{ marginTop: '15px' }}
          detailStyle={{ marginTop: '2px' }}
          disabled={disabled}
          children={
            <ListSelector
              selectedOptions={filter.roles}
              options={[...(data ? data.map(role => ({ id: role.id, value: role.id })) : [])]}
              onChange={e => setFilter({ ...filter, roles: e })}
            />
          }
        />
      ) : null}
    </>
  )
}
