import { useMsal } from '@azure/msal-react'
import { CircularProgress } from '@mui/material'
import { acquireAccessToken, CenteredDiv, ErrorMessage, useWtxLocalization } from '@wavetronix/common-components'
import axios from 'axios'
import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { env } from '../../index.js'
import AppCard from '../AppCard'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { makeStyles } from 'tss-react/mui'

import '../../../node_modules/react-grid-layout/css/styles.css'
import '../../../node_modules/react-resizable/css/styles.css'

const ReactGridLayout = WidthProvider(Responsive)
const useStyles = makeStyles()({
  root: {
    '& .react-grid-item>.react-resizable-handle::after': {
      visibility: 'hidden'
    },
    '& .react-grid-item>.react-resizable-handle': {
      margin: '20px',
      borderRight: '3px solid white',
      borderBottom: '3px solid white',
      background: 'none'
    },
    '& .react-grid-item.react-grid-placeholder': {
      background: 'black',
      borderRadius: '20px'
    }
  },
  buttonBase: {
    display: 'block',
    width: '95%',
    height: '95%',
    color: 'transparent'
  }
})

export default function LandingPage() {
  const { instance, accounts } = useMsal()
  const { classes } = useStyles()
  let localizedStrings = useWtxLocalization()

  let colValue = 0

  async function getMyViewableUIs() {
    let token = await acquireAccessToken(instance, accounts, env)
    return await axios
      .get(`${env.urls.gatekeeperURL}/api/userinterfaces/mine`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => res.data)
  }

  async function trySelfTag() {
    let token = await acquireAccessToken(instance, accounts, env)
    return await axios.get(`${env.urls.gatekeeperURL}/users/tagme/${env.basicAuthentication.fromCompany}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
  }

  const { isLoading, error, data } = useQuery({ queryKey: ['appData'], queryFn: getMyViewableUIs })

  useQuery({
    queryKey: ['tryselftag'],
    queryFn: trySelfTag
  })

  const cardLayout = useMemo(() => {
    if (data) {
      let baseLayout = { lg: [], md: [], sm: [], xs: [] }

      for (let uiApp of data) {
        if (uiApp.lg) baseLayout.lg.push({ i: uiApp.id, x: uiApp.lg.x, y: uiApp.lg.y, h: uiApp.lg.h, w: uiApp.lg.w })
        if (uiApp.md) baseLayout.md.push({ i: uiApp.id, x: uiApp.md.x, y: uiApp.md.y, h: uiApp.md.h, w: uiApp.md.w })
        if (uiApp.sm) baseLayout.sm.push({ i: uiApp.id, x: uiApp.sm.x, y: uiApp.sm.y, h: uiApp.sm.h, w: uiApp.sm.w })
        if (uiApp.xs) baseLayout.xs.push({ i: uiApp.id, x: uiApp.xs.x, y: uiApp.xs.y, h: uiApp.xs.h, w: uiApp.xs.w })
      }

      return baseLayout
    }
  }, [data])

  if (isLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }
  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <div style={{ padding: '40px 40px 80px 40px' }}>
      {data && data.length > 0 ? (
        <div className={classes.root}>
          <ReactGridLayout
            isDraggable={false}
            isResizable={false}
            className='layout'
            layouts={cardLayout}
            rowHeight={300}
            margin={[40, 40]}
            breakpoints={{ lg: 1400, md: 1200, sm: 500, xs: 370 }}
            cols={{ lg: 6, md: 4, sm: 2, xs: 1 }}
          >
            {data.map(item => (
              <div style={{ width: '100%', height: '100%' }} key={item.id} data-grid={{ x: colValue++ % 6, y: 0, w: 1, h: 1 }}>
                <AppCard env={env} app={item} key={item.id} />
              </div>
            ))}
          </ReactGridLayout>
        </div>
      ) : (
        <h1
          style={{
            margin: 'auto',
            textAlign: 'center',
            padding: 15,
            marginTop: 15
          }}
        >
          {localizedStrings.noAccess}
        </h1>
      )}
    </div>
  )
}
