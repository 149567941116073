import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class LicensesApi {
  deleteLicensesByUserId = async (msalInstance, accounts, userId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .delete(`${env.urls.updatesURL}/api/v1/licenses?userId=${userId}&licenseId=all`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }

  deleteLicensesV2ByUserId = async (msalInstance, accounts, userId) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return axios
      .delete(`${env.urls.updatesURL}/api/v2/licensesv2?userId=${userId}&licenseId=all`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(res => res.data)
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new LicensesApi()
  }
  return instance
}

export default getInstance()
