import { CenteredDiv, WtxColors, SetupProvider, createWtxAuthInstance } from '@wavetronix/common-components'

import React from 'react'
import { createRoot } from 'react-dom/client'
import MainPage from './components/MainPage'
import generateEnv from './env/generateEnv.js'
import './index.css'
import { en } from './lang/en.js'
import { fr } from './lang/fr.js'

export const env = await generateEnv()

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

if (window.location.pathname === '/logout') {
  window.localStorage.clear()
  window.location.href = window.location.origin
}

if (env.isError) {
  root.render(
    <CenteredDiv>
      <h3 style={{ color: WtxColors.INNOVATION_RED }}>{`${env.message}`}</h3>
    </CenteredDiv>
  )
} else {
  const msalInstance = await createWtxAuthInstance(env)

  root.render(
    <React.StrictMode>
      <SetupProvider
        env={env}
        msalInstance={msalInstance}
        allowedRoles={[]}
        languages={{
          en: en,
          fr: fr
        }}>
        <MainPage />
      </SetupProvider>
    </React.StrictMode>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
