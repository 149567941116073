import { useMsal } from '@azure/msal-react'
import { useQuery } from '@tanstack/react-query'
import { Switch, TextField } from '@mui/material'
import { CustomSelect } from '@wavetronix/common-components'
import { insertWhiteSpace } from '../../utils/ReleasePhaseMap'
import LaunchPhaseApi from '../../api/LaunchPhaseApi'
import SelectBaseProduct from '../otherComponents/SelectBaseProduct'

export const DEFAULT_UPDATES_FILTER = {
  baseProductId: '',
  itemNumber: '',
  version: '',
  releasePhase: 'All',
  showHidden: false,
  launchPhase: 'All'
}

export const filterUpdates = (filter, updates) => {
  return updates
    ? updates.filter(u => {
        u.releasePhase = insertWhiteSpace(u.releasePhase)
        return (
          (filter.baseProductId === '' || u.baseProductId === filter.baseProductId) &&
          u.itemNumber.toLowerCase().includes(filter.itemNumber.toLowerCase()) &&
          u.version.includes(filter.version) &&
          (filter.releasePhase === 'All' || u.releasePhase === filter.releasePhase) &&
          (filter.launchPhase === 'All' || u.launchPhase === filter.launchPhase) &&
          !(filter.showHidden === false && u.isHidden === true)
        )
      })
    : []
}

export default function UpdatesFilterDrawer({ filter, setFilter }) {
  const handleChange = (id, value) => setFilter(f => ({ ...f, [id]: value }))

  const onCheckChange = (id, checked) => setFilter(f => ({ ...f, [id]: checked }))

  const { instance, accounts } = useMsal()
  const { data: phaseData } = useQuery({
    queryKey: ['phasesFilterQuery'],
    queryFn: async () => await LaunchPhaseApi.getAllLaunchPhases(instance, accounts)
  })

  return (
    <>
      <SelectBaseProduct
        onChange={e => setFilter(f => ({ ...f, baseProductId: e.target.value }))}
        value={filter.baseProductId ? filter.baseProductId : ''}
        label='Type'
        style={{ width: '100%' }}
        variant='outlined'
        size='small'
      />
      <TextField
        id='filterItemNumberTextField'
        label='Item Number'
        style={{ width: '100%', marginTop: '15px' }}
        onChange={e => handleChange('itemNumber', e.target.value)}
        value={filter.itemNumber ? filter.itemNumber : ''}
        variant='outlined'
        size='small'
      />
      <TextField
        id='filterVersionTextField'
        label='Version Number'
        style={{ width: '100%', marginTop: '15px' }}
        onChange={e => handleChange('version', e.target.value)}
        value={filter.version ? filter.version : ''}
        variant='outlined'
        size='small'
      />
      <CustomSelect
        id='filterReleasePhaseSelect'
        style={{ width: '100%', marginTop: '15px' }}
        label='Release Phase'
        value={filter.releasePhase ? filter.releasePhase : ''}
        onChange={e => setFilter(f => ({ ...f, releasePhase: e.target.value }))}
        options={['All', 'Unreleased', 'Engineering', 'Beta Candidate', 'Beta', 'General']}
      />
      <CustomSelect
        id='filterLaunchPhaseSelect'
        style={{ width: '100%', marginTop: '15px' }}
        label='Launch Phase'
        value={filter.launchPhase}
        onChange={e => {
          setFilter(f => ({ ...f, launchPhase: e.target.value }))
        }}
        options={phaseData ? ['All', ...phaseData] : ['All']}
      />
      <div style={{ marginTop: '0px' }}>
        <Switch
          id='filterHiddenSwitch'
          color='primary'
          checked={filter.showHidden ? filter.showHidden : false}
          onChange={e => onCheckChange('showHidden', e.target.checked)}
        />
        Show Hidden
      </div>
    </>
  )
}
