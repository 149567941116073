import { TextField } from '@mui/material'

export const DEFAULT_ROLE_FILTER = {
  id: '',
  description: ''
}

export default function RoleFilterDrawer({ filter, setFilter }) {
  return (
    <>
      <TextField
        id='roleNameTextField'
        label={'Role Name'}
        size='small'
        variant='outlined'
        style={{ width: '100%' }}
        onChange={e => setFilter(s => ({ ...s, id: e.target.value }))}
        value={filter.id}
      />

      <TextField
        id='descriptionTextField'
        label={'Description'}
        size='small'
        variant='outlined'
        style={{ width: '100%', marginTop: '15px' }}
        onChange={e => setFilter(s => ({ ...s, description: e.target.value }))}
        value={filter.description}
      />
    </>
  )
}
