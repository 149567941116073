import { Tab, Tabs } from '@mui/material'
import { WtxColors, checkHasAccess } from '@wavetronix/common-components'
import { env } from '../../../index.js'
import { useMemo } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    alignItems: 'end',
    padding: '0px 16px 0px 0px',
    color: 'black'
  },
  tabParent: {
    width: '186px'
  }
}

const TAB_OPTIONS = [
  {
    roles: ['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member', 'Dealer'],
    viewIfDealerGroup: true,
    id: 'overviewTab',
    label: 'Overview',
    value: 'overview'
  },
  {
    roles: ['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member', 'Dealer'],
    viewIfDealerGroup: true,
    id: 'permissionsTab',
    label: 'Permissions',
    value: 'permissions'
  },
  {
    roles: ['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member', 'Dealer'],
    viewIfDealerGroup: true,
    id: 'usersTab',
    label: 'Users',
    value: 'users'
  },
  {
    roles: ['Gatekeeper Admin', 'Expanse Admin'],
    viewIfDealerGroup: false,
    id: 'updatesTab',
    label: 'Updates',
    value: 'updates'
  },
  {
    roles: ['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member'],
    viewIfDealerGroup: false,
    id: 'documentsTab',
    label: 'Documents',
    value: 'documents'
  },
  {
    roles: ['Gatekeeper Admin', 'Expanse Admin', 'Sales Admin', 'Sales Team Member'],
    viewIfDealerGroup: false,
    id: 'packagesTab',
    label: 'Packages',
    value: 'packages'
  }
]

export default function EditGroupNavbar({ value, accounts, isDealerGroup, onNavChange }) {
  const userTabOptions = useMemo(() => {
    return TAB_OPTIONS.filter(option => {
      let dealerShow = isDealerGroup ? isDealerGroup === option.viewIfDealerGroup : true
      return dealerShow && checkHasAccess(option.roles, accounts, env)
    })
  }, [accounts, isDealerGroup])

  return (
    <Tabs
      value={value}
      TabIndicatorProps={{
        sx: {
          backgroundColor: WtxColors.IQ_BLUE
        }
      }}
      orientation='vertical'
      sx={classes.tabParent}
    >
      <Tab
        sx={classes.tab}
        key='groupBackButton'
        id='groupBackButton'
        value='groupBackButton'
        label={
          <div style={{ display: 'flex' }}>
            <ArrowBackIcon sx={{ paddingBottom: '4px', marginRight: '20px' }} />
            Back to Groups
          </div>
        }
        onClick={() => onNavChange('groupBackButton')}
      />
      {userTabOptions.map(option => {
        return (
          <Tab
            key={option.id}
            id={option.id}
            value={option.value}
            sx={classes.tab}
            label={option.label}
            onClick={() => onNavChange(option.value)}
          />
        )
      })}
    </Tabs>
  )
}
