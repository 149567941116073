const baseIdProductHash = {
  DESKTOP: 'Expanse',
  XROADS: 'CrossRoads',
  CING: 'Arc',
  LEGACY_HD: 'HD',
  HDA: 'XP20',
  XP20: 'XP20',
  ARCPLUS: 'Arc 5',
  ARC6: 'Arc 6',
  XP21: 'XP21',
  LEGACY_MATRIX: 'Matrix',
  SSMMATRIX: 'SSM Matrix',
  SSMADVANCE: 'SSM Advance',
  SSMHD: 'SSM HD',
  SSMV: 'SSM V',
  CLICKSUPERVISOR: 'Click Supervisor',
  CLICKUPDATEUTILITY: 'Click Supervisor Deployment Utility',
  CLICK65X: 'Click 65X',
  MSDOTNET1: 'Microsoft .NET v1.1',
  USB2RS485: 'USB to RS-485 Driver',
  DATAEXPRESS: 'Data Express'
}

const typeAccessHash = {
  documentsAccessLevel: 'Document Access Level',
  updatesAccessLevel: 'Release Access Level'
}

export const SOFTWARE_LEVELS = ['General', 'FullRelease', 'Unreleased']
export const DOCUMENT_LEVELS = ['Basic', 'Intermediate', 'Advanced']

const typeLevelsAccessHash = {
  documentsAccessLevel: DOCUMENT_LEVELS,
  updatesAccessLevel: SOFTWARE_LEVELS
}

export const DEFAULT_NEXUS_PERMISSIONS = [
  {
    type: 'updatesAccessLevel',
    permissionLevel: SOFTWARE_LEVELS[0]
  },
  {
    type: 'documentsAccessLevel',
    permissionLevel: DOCUMENT_LEVELS[0]
  }
]

export function getDeviceName(id) {
  if (id in baseIdProductHash) return baseIdProductHash[id]
  else return id
}

export function getAccessType(id) {
  if (id in typeAccessHash) return typeAccessHash[id]
  else return id
}

export function getAccessTypeLevels(id) {
  if (id in typeLevelsAccessHash) return typeLevelsAccessHash[id]
  else return []
}
