import { CenteredDiv, CustomDataGrid, ErrorMessage, RegularButton, PersistantFilterDiv } from '@wavetronix/common-components'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useMsal } from '@azure/msal-react'
import RoleFilterDrawer, { DEFAULT_ROLE_FILTER } from '../drawers/RoleFilterDrawer'
import AddRoleModal from '../modals/AddRoleModal'
import EditRoleModal from '../modals/EditRoleModal'
import { Divider } from '@mui/material'
import GatekeeperApi from '../../api/GatekeeperApi'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const nullCheck = s => (s ? s : '')

const filterRoles = (filter, roles) =>
  roles.filter(
    r =>
      nullCheck(r.id).toLowerCase().includes(filter.id.toLowerCase()) &&
      nullCheck(r.description).toLowerCase().includes(filter.description.toLowerCase())
  )

export default function RolesPage() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen

  const { instance, accounts } = useMsal()
  const [filter, setFilter] = useState(DEFAULT_ROLE_FILTER)
  const [pageSize, setPageSize] = useState(100)
  const [editRoleDrawerState, setEditRoleDrawerState] = useState({
    open: false,
    role: {}
  })
  const [addRoleDrawerState, setAddRoleDrawerState] = useState(false)

  document.title = 'Nexus Management'

  const columns = [
    {
      field: 'id',
      headerName: 'Role Name',
      flex: 0.5,
      disableColumnMenu: true,
      sortable: true
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      disableColumnMenu: true,
      sortable: true
    }
  ]

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ['roles'],
    queryFn: async () => await GatekeeperApi.getRoles(instance, accounts)
  })

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <PersistantFilterDiv
      defaultOpen={largeScreenFeaturesActive}
      resetFilter={() => setFilter(DEFAULT_ROLE_FILTER)}
      drawer={<RoleFilterDrawer setFilter={setFilter} filter={filter} />}
      page={
        <>
          <div style={{ justifyContent: 'space-between', display: 'flex' }}>
            <div style={{ justifyContent: 'left', flex: 1 }}>
              <RegularButton id='addRoleStateModalButton' onClick={() => setAddRoleDrawerState(true)} style={{ margin: '16px' }}>
                Add Role
              </RegularButton>
            </div>
            <div></div>
          </div>
          <Divider style={{ height: '0px', borderColor: 'transparent' }} />
          <CustomDataGrid
            rows={data ? filterRoles(filter, data) : []}
            autoHeight
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={p => setPageSize(p)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            disableSelectionOnClick
            loading={isLoading}
            style={{ margin: '0px 0px 0px 24px' }}
            onRowClick={e => setEditRoleDrawerState({ open: true, role: e.row })}
            cursor='pointer'
          />

          <EditRoleModal
            open={editRoleDrawerState.open}
            onClose={() => {
              setEditRoleDrawerState({ open: false, role: {} })
            }}
            refetch={refetch}
            role={editRoleDrawerState.role}
          />

          <AddRoleModal
            open={addRoleDrawerState}
            onClose={() => {
              setAddRoleDrawerState(false)
            }}
            refetch={refetch}
          />
        </>
      }
    />
  )
}
