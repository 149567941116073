import { Grid, TextField, Tooltip, InputAdornment, Box } from '@mui/material'
import { CenteredDiv, WtxColors, HasAccess, PrimaryButton, SnackbarVariants, GhostButton } from '@wavetronix/common-components'
import InfoIcon from '@mui/icons-material/Info.js'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

import GroupsRegionsMap from '../../gridComponents/GroupsRegionsMap'
import ItemTimeline from '../../gridComponents/ItemTimeline'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'
import { env } from '../../../index.js'
import { PERMISSABLE_ADMINS } from '../../modals/AddGroupModal'
import VerifyDeleteModal from '../../modals/VerifyDeleteModal'
import GatekeeperApi from '../../../api/GatekeeperApi.js'
import { clearState } from '../../../customhooks/useCrossTabState'
import { DEFAULT_GROUP } from '../../modals/AddGroupModal'

export default function EditGroupOverview({
  isDealerGroup,
  group,
  setGroup,
  isReadOnly,
  instance,
  accounts,
  isUploading,
  setIsUploading,
  groupHasChanges,
  updateGroup
}) {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [deleteModelOpen, setDeleteModelOpen] = useState(false)

  const deleteGroup = async () => {
    await GatekeeperApi.deleteGroup(instance, accounts, group.id).then(
      _ => {
        enqueueSnackbar('Delete group success', SnackbarVariants.SUCCESS)
        clearState(`editGroup`, DEFAULT_GROUP)
        clearState(`editGroupState`, 'overview')
        navigate(`/groups`)
      },
      error => {
        enqueueSnackbar(`Failed to delete group`, SnackbarVariants.ERROR)
      }
    )
  }
  return (
    <>
      <VerifyDeleteModal
        title={'Delete Group'}
        open={deleteModelOpen}
        onClose={() => setDeleteModelOpen(false)}
        onDelete={async () => await deleteGroup()}
        message={
          <div>
            <p>Do you want to delete this group and remove all references to it for all users?</p> This means removing any
            PERMISSIONS and ROLES that a user may have through this group. <p>This action is permanent and cannot be undone.</p>
          </div>
        }
      />
      <Grid container spacing={2}>
        <Grid item md={12} sm={12} xs={12}>
          <Box sx={{ float: 'right' }}>
            <PrimaryButton
              style={{ width: '200px' }}
              disabled={isUploading || isReadOnly || !Object.values(groupHasChanges).some(element => element === true)}
              onClick={async () => {
                setIsUploading(true)
                await updateGroup('overview')
                setIsUploading(false)
              }}
            >
              Save
            </PrimaryButton>
          </Box>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <TextField
                label={'Group Name'}
                variant='outlined'
                sx={{ width: '100%' }}
                size='small'
                value={group.groupName}
                onChange={e => setGroup(g => ({ ...g, groupName: e.target.value }))}
                disabled={isUploading || isReadOnly}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position='end'
                      sx={{ color: WtxColors.IQ_BLUE, display: groupHasChanges.nameHasChanges ? 'default' : 'none' }}
                    >
                      <ChangeCircleIcon />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              {isDealerGroup ? (
                <>
                  <TextField
                    disabled={true}
                    label={'Description'}
                    value={
                      group.dealerStates
                        ? (group.groupName ? group.groupName : '<DealerName>') +
                          ' dealer group. Admins can manage access for users in the following regions:\n' +
                          (group.dealerStates.length > 0 ? group.dealerStates.sort().join(', ') : 'None specified')
                        : ''
                    }
                    variant='outlined'
                    sx={{ width: '100%' }}
                    multiline
                    minRows={4}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' sx={{ position: 'absolute', bottom: 15, right: 5 }}>
                          <Tooltip title="Dealer groups' descriptions will be automatically populated based on other fields.">
                            <InfoIcon />
                          </Tooltip>
                        </InputAdornment>
                      )
                    }}
                  />
                </>
              ) : (
                <TextField
                  disabled={isUploading || isReadOnly}
                  label={'Description'}
                  value={group.groupDescription}
                  variant='outlined'
                  sx={{ width: '100%' }}
                  multiline
                  minRows={4}
                  onChange={e => setGroup(g => ({ ...g, groupDescription: e.target.value }))}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position='end'
                        sx={{
                          color: WtxColors.IQ_BLUE,
                          position: 'absolute',
                          bottom: 15,
                          right: 5,
                          display: groupHasChanges.descriptionHasChanges ? 'default' : 'none'
                        }}
                      >
                        <ChangeCircleIcon />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            </Grid>
            {isDealerGroup ? (
              <Grid item md={12} sm={12} xs={12}>
                <GroupsRegionsMap
                  group={group}
                  setGroup={setGroup}
                  isUploading={isUploading}
                  isReadOnly={isReadOnly}
                  groupHasChanges={groupHasChanges}
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <HasAccess env={env} allowedRoles={PERMISSABLE_ADMINS}>
              <CenteredDiv>
                <GhostButton
                  disabled={isUploading || isReadOnly}
                  sx={{
                    backgroundColor: 'transparent',
                    color: WtxColors.INNOVATION_RED,
                    textTransform: 'none',
                    borderRadius: '30px',
                    boxShadow: 'none',
                    border: '2px solid',
                    width: '200px',
                    marginTop: '15px',
                    borderColor: WtxColors.INNOVATION_RED,
                    '&:hover': {
                      background: WtxColors.INNOVATION_RED,
                      borderColor: WtxColors.INNOVATION_RED,
                      color: 'white'
                    }
                  }}
                  onClick={e => setDeleteModelOpen(true)}
                >
                  Delete
                </GhostButton>
              </CenteredDiv>
            </HasAccess>
          </Grid>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <CenteredDiv>
            <h4>Group History</h4>
          </CenteredDiv>
          <ItemTimeline history={group.history} />
        </Grid>
      </Grid>
    </>
  )
}
