import { useState } from 'react'
import dayjs from 'dayjs'
import Papa from 'papaparse'
import { Box, TextField, ButtonGroup, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  CustomDataGrid,
  useWtxLocalization,
  WtxColors,
  CenteredDiv,
  downloadBlob,
  RegularButton
} from '@wavetronix/common-components'
import { getCountryHash } from '../../utils/countryUtil.js'

const classes = {
  dateRoot: {
    width: '200px',
    '.MuiInputBase-root': { fontSize: '15px' },
    '.MuiButtonBase-root': {
      padding: '5px 5px 5px 0px'
    }
  },
  activeButton: {
    backgroundColor: WtxColors.IQ_BLUE,
    color: 'white',
    textTransform: 'none',
    borderRadius: '30px',
    width: '100px',
    boxShadow: 'none',
    border: '2px solid',
    borderColor: WtxColors.IQ_BLUE,
    '&:hover': {
      borderColor: WtxColors.IQ_BLUE
    }
  },
  inActiveButton: {
    backgroundColor: 'white',
    color: WtxColors.IQ_BLUE,
    textTransform: 'none',
    borderRadius: '30px',
    width: '100px',
    boxShadow: 'none',
    border: '2px solid',
    borderColor: WtxColors.IQ_BLUE,
    '&:hover': {
      borderColor: WtxColors.IQ_BLUE,
      color: 'white'
    }
  }
}
export default function GroupsUsersTable({
  groupId,
  isDealerGroup,
  filter,
  setFilter,
  users,
  overridesComparator,
  renderSwitch,
  isLoading
  // usersGroupMap,
  // setFilteredUserData,
}) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen

  const localizedStrings = useWtxLocalization()
  const [pageSize, setPageSize] = useState(20)
  const columns = [
    {
      field: 'override',
      headerName: 'Add',
      flex: 0.75,
      valueGetter: params => params.row.id,
      sortComparator: overridesComparator,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive,
      renderCell: value => renderSwitch(value.row)
    },
    {
      field: 'givenName',
      headerName: localizedStrings.givenName,
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive,
      hide: isSmallScreen === true
    },
    {
      field: 'surname',
      headerName: localizedStrings.surname,
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen === true
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive,
      hide: isSmallScreen === false,
      valueGetter: data => `${data.row.givenName} ${data.row.surname}`
    },
    {
      field: 'company',
      headerName: localizedStrings.company,
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen
    },
    {
      field: 'email',
      headerName: localizedStrings.email,
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      field: 'createdDate',
      headerName: 'Date Created',
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen,
      valueGetter: u => dayjs(u.row.createdDate).format('MM/DD/YYYY')
    },
    {
      field: 'country',
      headerName: localizedStrings.region,
      flex: 0.5,
      disableColumnMenu: true,
      sortable: true,
      valueGetter: u => getCountryHash(u.row.country)
    }
  ]

  function GetGroupStatus(user) {
    return user.groups.includes(groupId) ? 'Has Access' : 'Restricted'
  }

  return (
    <>
      <div style={{ width: '100%', display: 'flex', marginBottom: '15px' }}>
        <Box style={{ width: '100%', display: 'flex', marginTop: '18px' }}>
          <DatePicker
            id='newUsersCreatedDatePicker'
            label='New Users as of'
            inputFormat='MM/DD/YYYY'
            value={filter.createdDate ? filter.createdDate : null}
            onChange={date => setFilter(f => ({ ...f, createdDate: date }))}
            renderInput={params => <TextField sx={{ ...classes.dateRoot }} {...params} size='small' />}
          />
          <ButtonGroup variant='contained' size='small' sx={{ boxShadow: 'none', marginLeft: '15px' }}>
            <Button
              sx={{ color: filter.showRecentOnly ? classes.activeButton : classes.inActiveButton }}
              onClick={() => setFilter(f => ({ ...f, showRecentOnly: true }))}
            >
              RECENT
            </Button>
            <Button
              sx={{ color: !filter.showRecentOnly ? classes.activeButton : classes.inActiveButton }}
              onClick={() => setFilter(f => ({ ...f, showRecentOnly: false }))}
            >
              ALL
            </Button>
          </ButtonGroup>
          <Box sx={{ flex: '1 1 auto' }} />
          <RegularButton
            style={{ width: '200px' }}
            onClick={() => {
              if (users) {
                let transformedData = users.map(user => ({
                  'First Name': user.givenName,
                  'Last Name': user.surname,
                  Company: user.company,
                  'E-Mail': user.email,
                  Location: user.country,
                  'Activation Status': user.activationStatus,
                  'Group Status': GetGroupStatus(user)
                }))
                let csvData = Papa.unparse(JSON.stringify(transformedData))
                downloadBlob(csvData, 'user_data.csv', 'text/csv;charset=utf-8;')
              }
            }}
          >
            Export Users
          </RegularButton>
        </Box>
      </div>

      {isDealerGroup && users.length === 0 ? (
        <Box>
          <CenteredDiv>No available users that match this (dealer) group's regions.</CenteredDiv>
        </Box>
      ) : (
        <Box sx={{ width: '100%' }}>
          <CustomDataGrid
            rows={users}
            autoHeight
            isLoading={isLoading}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={p => setPageSize(p.pageSize)}
            rowsPerPageOptions={[10, 20, 50, 100]}
            disableSelectionOnClick
            disableExtendRowFullWidth={true}
            initialState={{
              sorting: {
                sortModel: [
                  {
                    field: 'override',
                    sort: 'asc'
                  }
                ]
              }
            }}
          />{' '}
        </Box>
      )}
    </>
  )
}
