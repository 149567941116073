import { FormControl, InputLabel, MenuItem, Select, ListSubheader } from '@mui/material'
import React from 'react'
// This can be used to render a customized select without custom individual styling for
// its options. If you need to customize the options individually, it is best to use the
// Material UI FormControl and Select components directly.
export default function RegionSelector({ style, required, onChange, value, label, options, disabled, id, regionIdInfo }) {
  return (
    <FormControl style={style} fullWidth>
      <InputLabel id='selectLabel'>{label}</InputLabel>
      <Select
        size='small'
        id={id}
        variant='outlined'
        labelId='selectLabel'
        value={value}
        onChange={onChange}
        label={label}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Maximum height of the dropdown
              disableScrollLock: true
            }
          }
        }}
      >
        {options
          ? options.flatMap((continentData, index) => [
              // Continent as sticky ListSubheader
              <ListSubheader
                key={`${continentData.continent}continent`}
                style={{
                  backgroundColor: '#fff', // Ensure the background matches the dropdown
                  position: 'sticky',
                  top: 0,
                  zIndex: 1
                }}
              >
                {continentData.continent}
              </ListSubheader>,
              // Regions under each continent
              ...continentData.regions.map(region => (
                <MenuItem key={`${region.id}region`} value={region.id}>
                  {regionIdInfo[region.id].name}
                </MenuItem>
              ))
            ])
          : []}
      </Select>
    </FormControl>
  )
}
