import { CustomDataGrid } from '@wavetronix/common-components'
import React, { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const nullCheck = s => (s ? s : '')

function hasRoleAccess(user, isInternal) {
  let email = nullCheck(user.email)
  let pattern = /^[A-Za-z0-9._%+-]+@42square\.com$/

  if (user.type === 'InternalUser' || pattern.test(email)) {
    return true
  } else if (user.type === 'ExternalUser') {
    return isInternal === true ? false : true
  }
}

const filterRoles = (filter, roles, user) =>
  roles.filter(r => {
    return (
      nullCheck(r.id).toLowerCase().includes(filter.id.toLowerCase()) &&
      nullCheck(r.description).toLowerCase().includes(filter.description.toLowerCase()) &&
      hasRoleAccess(user, r.isInternal)
    )
  })

export default function UserRolesTable({ filter, roles, rolesLoading, renderSwitch, user }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen

  document.title = 'Nexus Management'

  const [pageSize, setPageSize] = useState(100)

  const columns = [
    {
      field: 'override',
      headerName: 'Add',
      flex: 0.25,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive,
      renderCell: data => renderSwitch(data)
    },
    {
      field: 'id',
      headerName: 'Role Name',
      flex: 0.5,
      sortable: true,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen
    }
  ]

  return (
    <div style={{ width: '100%' }}>
      <CustomDataGrid
        rows={user ? filterRoles(filter, roles, user) : []}
        autoHeight
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={p => setPageSize(p)}
        rowsPerPageOptions={[10, 20, 50, 100]}
        disableSelectionOnClick
        loading={rolesLoading}
        style={{ marginTop: '15px' }}
      />
    </div>
  )
}
