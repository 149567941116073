import { Grid, Tooltip, Switch, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material'
import { CustomAccordion, CustomCheckbox, CustomSelect, HasAccess, WtxColors, PrimaryButton } from '@wavetronix/common-components'
import { env } from '../../../index.js'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { PERMISSABLE_ADMINS } from '../../modals/AddGroupModal'
import { getAccessType, getAccessTypeLevels } from '../../../utils/PermissionsMap'
import AdvancedRolesSelector from '../../controls/AdvancedRolesSelector'
import AdvancedRolesViewer from '../../controls/AdvancedRolesViewer'
import GroupsUserAdminTable from '../../gridComponents/GroupsUserAdminTable'
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'

const NEXUS_ACCESS_ROLES = {
  wavetronix: 'Approved Customer',
  islandradar: 'Island Radar Customer',
  fathom: 'Fathom Customer'
}

export default function EditGroupPermissions({
  isDealerGroup,
  group,
  setGroup,
  updateGroup,
  instance,
  accounts,
  isUploading,
  setIsUploading,
  usersMap,
  usersData,
  isReadOnly,
  groupHasChanges,
  updateGroupChangeType,
  generateListDiff
}) {
  const onCheckChangeAdminPermission = async (e, role) => {
    let checked = e.target.checked
    if (checked) {
      setGroup(g => ({ ...g, permissableAdmins: [...group.permissableAdmins, role] }))
    } else {
      setGroup(g => ({ ...g, permissableAdmins: group.permissableAdmins.filter(r => r !== role) }))
    }
  }

  const onCheckChange = async (e, path) => {
    let checked = e.target.checked
    if (checked) {
      setGroup(g => ({ ...g, roles: [...group.roles, path] }))
    } else {
      setGroup(g => ({ ...g, roles: group.roles.filter(r => r !== path) }))
    }
  }

  async function onUserSwitchChange(e, user) {
    setIsUploading(true)
    if (e.target.checked) {
      setGroup(g => ({ ...g, admins: [...g.admins, user.id] }))
    } else {
      setGroup(g => ({ ...g, admins: g.admins.filter(uId => uId !== user.id) }))
    }
    setIsUploading(false)
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <Box sx={{ float: 'right' }}>
          <HasAccess
            env={env}
            allowedRoles={PERMISSABLE_ADMINS}
            unauthorizedControl={
              <PrimaryButton style={{ width: '200px' }} disabled>
                Save
              </PrimaryButton>
            }
          >
            <PrimaryButton
              style={{ width: '200px' }}
              disabled={isUploading || isReadOnly || !Object.values(groupHasChanges).some(element => element === true)}
              onClick={async () => {
                setIsUploading(true)
                await updateGroup('permissions')
                setIsUploading(false)
              }}
            >
              Save
            </PrimaryButton>
          </HasAccess>
        </Box>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <CustomAccordion
              expanded
              title={
                <Box sx={{ display: 'flex' }}>
                  Nexus Permissions{' '}
                  <ChangeCircleIcon
                    sx={{
                      color: WtxColors.IQ_BLUE,
                      display: groupHasChanges.rolesHasChanges ? 'default' : 'none',
                      marginLeft: '5px'
                    }}
                  />
                </Box>
              }
              style={{ marginTop: '15px' }}
              detailStyle={{ marginTop: '2px' }}
              children={
                <Grid container>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <div style={{ display: 'flex', marginLeft: '10px' }}>
                      <CustomCheckbox
                        onChange={e => onCheckChange(e, NEXUS_ACCESS_ROLES[env.basicAuthentication.fromCompany])}
                        checked={group.roles.includes(NEXUS_ACCESS_ROLES[env.basicAuthentication.fromCompany])}
                        disabled={isUploading || isReadOnly}
                      />
                      <Tooltip title='Gives user access to My Documents, My Software, and My License cards and activates General software/firmware access and Basic documentation access.'>
                        <div style={{ marginTop: '8px' }}>Nexus Access</div>
                      </Tooltip>
                    </div>
                  </Grid>

                  {env.basicAuthentication.fromCompany === 'wavetronix' && !(group && group.dealerGroup) ? (
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                      <div style={{ display: 'flex', marginLeft: '10px' }}>
                        <CustomCheckbox
                          onChange={e => onCheckChange(e, 'XRoads Basic User')}
                          checked={group.roles.includes('XRoads Basic User')}
                          disabled={isUploading || isReadOnly}
                        />
                        <div style={{ marginTop: '8px' }}>CrossRoads Basic</div>
                      </div>
                      <div style={{ display: 'flex', marginLeft: '10px' }}>
                        <CustomCheckbox
                          onChange={e => onCheckChange(e, 'XRoads Premium User')}
                          checked={group.roles.includes('XRoads Premium User')}
                          disabled={isUploading || isReadOnly}
                        />
                        <div style={{ marginTop: '8px' }}>CrossRoads Premium</div>
                      </div>
                    </Grid>
                  ) : null}

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box sx={{ margin: '15px', display: 'flex' }}>
                      <FormControl fullWidth variant='outlined' size='small'>
                        <InputLabel id='accessLevelSelectLabel'>{getAccessType('updatesAccessLevel')}</InputLabel>
                        <Select
                          disabled={isUploading || isReadOnly}
                          labelId='accessLevelSelectLabel'
                          value={
                            group.updatesAccessLevel[env.basicAuthentication.fromCompany]
                              ? group.updatesAccessLevel[env.basicAuthentication.fromCompany]
                              : ''
                          }
                          onChange={e => {
                            setGroup(g => ({
                              ...g,
                              updatesAccessLevel: {
                                ...g.updatesAccessLevel,
                                [`${env.basicAuthentication.fromCompany}`]: e.target.value
                              }
                            }))
                          }}
                          label={getAccessType('updatesAccessLevel')}
                        >
                          {getAccessTypeLevels('updatesAccessLevel').map(accessLevel => (
                            <MenuItem value={accessLevel} key={accessLevel}>
                              {accessLevel === 'FullRelease' ? 'QA Release' : accessLevel}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <ChangeCircleIcon
                        sx={{
                          display: groupHasChanges.updatesAccessHasChanges ? 'default' : 'none',
                          color: WtxColors.IQ_BLUE,
                          marginTop: '8px',
                          marginLeft: '8px'
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box sx={{ margin: '15px', display: 'flex' }}>
                      <CustomSelect
                        disabled={isUploading || isReadOnly}
                        style={{ width: '100%' }}
                        label={getAccessType('documentsAccessLevel')}
                        value={
                          group.documentsAccessLevel[env.basicAuthentication.fromCompany]
                            ? group.documentsAccessLevel[env.basicAuthentication.fromCompany]
                            : ''
                        }
                        onChange={e => {
                          setGroup(g => ({
                            ...g,
                            documentsAccessLevel: {
                              ...g.documentsAccessLevel,
                              [`${env.basicAuthentication.fromCompany}`]: e.target.value
                            }
                          }))
                        }}
                        options={getAccessTypeLevels('documentsAccessLevel')}
                      />
                      <ChangeCircleIcon
                        sx={{
                          display: groupHasChanges.docAccessHasChanges ? 'default' : 'none',
                          color: WtxColors.IQ_BLUE,
                          marginTop: '8px',
                          marginLeft: '8px'
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
          {!group.dealerGroup ? (
            <Grid item md={12} sm={12} xs={12}>
              <HasAccess
                env={env}
                allowedRoles={['Gatekeeper Admin']}
                unauthorizedControl={<AdvancedRolesViewer roles={group.roles} disabled={isUploading || isReadOnly} />}
              >
                <AdvancedRolesSelector
                  selectedRoles={group.roles.map(r => ({ id: r, value: r }))}
                  onChange={e => {
                    setGroup(g => ({ ...g, roles: e.map(r => r.id) }))
                  }}
                  disabled={isUploading || isReadOnly}
                  title={
                    <Box sx={{ display: 'flex' }}>
                      <div style={{ display: 'flex' }}>
                        Nexus Permissions - Advanced
                        <Tooltip title='Can enable any Nexus permissions via roles on this group' sx={{ marginLeft: '5px' }}>
                          <HelpOutlineIcon />
                        </Tooltip>
                      </div>
                      <ChangeCircleIcon
                        sx={{
                          color: WtxColors.IQ_BLUE,
                          display: groupHasChanges.rolesHasChanges ? 'default' : 'none',
                          marginLeft: '5px'
                        }}
                      />
                    </Box>
                  }
                />
              </HasAccess>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <CustomAccordion
              expanded
              title={
                <div style={{ display: 'flex' }}>
                  Role Administrators
                  <Tooltip
                    title='Defines which admins (by role) can edit permissions and roles, assign users, and add overrides to a group. Gatekeeper Admins are always allowed. Sales Team Members can only be assigned as (role) administrators on dealer groups'
                    sx={{ marginLeft: '5px' }}
                  >
                    <HelpOutlineIcon />
                  </Tooltip>
                  <ChangeCircleIcon
                    sx={{
                      color: WtxColors.IQ_BLUE,
                      display: groupHasChanges.roleAdminHasChanges ? 'default' : 'none',
                      marginLeft: '5px'
                    }}
                  />
                </div>
              }
              detailStyle={{ marginTop: '2px' }}
              children={
                <>
                  {PERMISSABLE_ADMINS.map(role => {
                    return (
                      <div style={{ display: 'flex', marginLeft: '10px' }} key={role}>
                        <Switch
                          checked={group.permissableAdmins.includes(role)}
                          color={group.permissableAdmins.includes(role) ? 'primary' : 'secondary'}
                          onChange={e => onCheckChangeAdminPermission(e, role)}
                          disabled={
                            role === 'Gatekeeper Admin'
                              ? true
                              : role === 'Sales Team Member' && !isDealerGroup
                              ? true
                              : isUploading || isReadOnly
                          }
                        />
                        <div style={{ marginTop: '8px' }}>{role}</div>
                      </div>
                    )
                  })}
                </>
              }
            />
          </Grid>
          {group.dealerGroup ? (
            <Grid item md={12} sm={12} xs={12}>
              <Box>
                <Box style={{ display: 'flex', marginBottom: '15px' }}>
                  <div style={{ display: 'flex' }}>
                    User Administrators
                    <Tooltip
                      title='Defines which admins (by user) can add users to a DEALER group. Only users with the dealer role can be added as admins.'
                      sx={{ marginLeft: '5px' }}
                    >
                      <HelpOutlineIcon />
                    </Tooltip>
                  </div>
                  <ChangeCircleIcon
                    sx={{
                      color: WtxColors.IQ_BLUE,
                      display: groupHasChanges.userAdminHasChanges ? 'default' : 'none',
                      marginLeft: '5px'
                    }}
                  />
                </Box>
                <GroupsUserAdminTable
                  usersData={usersData}
                  overridesComparator={(a, b) => {
                    if (usersMap[a] === true && usersMap[b] === false) {
                      return -1
                    } else if (usersMap[a] === false && usersMap[b] === true) {
                      return 1
                    } else {
                      return 0
                    }
                  }}
                  renderSwitch={user => {
                    return (
                      <Switch
                        checked={group.admins.length > 0 ? group.admins.includes(user.id) : false}
                        color={
                          (group.admins.length > 0 ? group.admins.includes(user.id) : false) === true ? 'primary' : 'secondary'
                        }
                        onChange={e => onUserSwitchChange(e, user)}
                        disabled={isUploading || isReadOnly}
                      />
                    )
                  }}
                />
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}
