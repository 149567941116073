export const listHaveDifferences = (list1, list2) => {
  // Sort both lists
  const sortedList1 = [...list1].sort()
  const sortedList2 = [...list2].sort()

  // Check if the lengths are different
  if (sortedList1.length !== sortedList2.length) {
    return true
  }

  // Check if any elements are different
  for (let i = 0; i < sortedList1.length; i++) {
    if (sortedList1[i] !== sortedList2[i]) {
      return true
    }
  }

  // No differences found
  return false
}

export const getRemovedValues = (oldList, newList) => {
  return oldList.filter(item => !newList.includes(item))
}

export const getAddedValues = (oldList, newList) => {
  return newList.filter(item => !oldList.includes(item))
}
