import { useMsal } from '@azure/msal-react'
import { CircularProgress, TextField } from '@mui/material'
import { CenteredDiv, CustomAccordion, CustomSelect, ErrorMessage, useWtxLocalization } from '@wavetronix/common-components'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import CategoriesApi from '../../api/CategoriesApi'
import { containsSubstring } from '../../utils/stringUtils'
import ProductTypeSelector from '../controls/ProductTypeSelector'

export const DEFAULT_DOCUMENTS_FILTER = {
  name: '',
  accessLevel: 'All Levels',
  productTypes: [],
  documentTypes: [],
  description: '',
  showHidden: false,
  showOverrides: false
}

export const filterDocuments = (filter, documents) =>
  documents
    ? documents.filter(d => {
        return (
          (d.accessLevel === filter.accessLevel || filter.accessLevel === 'All Levels') &&
          (containsSubstring(d.fileName, filter.name) || filter.name === '') &&
          (containsSubstring(d.description, filter.description) || filter.description === '') &&
          (d.products.filter(p => filter.productTypes.map(fp => fp.id).includes(p)).length > 0 ||
            filter.productTypes.length === 0) &&
          (filter.documentTypes.map(df => df.id).includes(d.documentType) || filter.documentTypes.length === 0)
        )
      })
    : []

export default function DocumentsFilterDrawer({ filter, setFilter }) {
  const { instance, accounts } = useMsal()
  const [documentTypes, setDocumentTypes] = useState([])
  const [productTypes, setProductTypes] = useState([])
  let localizedStrings = useWtxLocalization()

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError
  } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      let categories = await CategoriesApi.getCategories(instance, accounts)
      return categories
    }
  })

  useEffect(() => {
    if (categories) {
      let docTypes = categories.filter(c => c.categoryType === 'Document Type')
      let productTypes = categories.filter(c => c.categoryType === 'Product')

      setDocumentTypes(docTypes)
      setProductTypes(productTypes)
    }
  }, [categories])

  if (categoriesLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }
  if (categoriesError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={categoriesError} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <TextField
        id='filterNameTextField'
        style={{ width: '100%' }}
        label={localizedStrings.name}
        size='small'
        value={filter.name ? filter.name : ''}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, name: e.target.value }))}
      />
      <CustomSelect
        id='filterAccessLevelSelect'
        style={{ width: '100%', marginTop: 15 }}
        label={localizedStrings.accessLevel}
        value={filter.accessLevel ? filter.accessLevel : ''}
        onChange={e => setFilter(f => ({ ...f, accessLevel: e.target.value }))}
        options={['All Levels', 'Advanced', 'Intermediate', 'Basic']}
      />
      <CustomAccordion
        title={localizedStrings.products}
        style={{ marginTop: '15px' }}
        children={
          <ProductTypeSelector
            selectorStyle={{ width: '100%' }}
            documentProductTypes={filter.productTypes ? filter.productTypes : []}
            productTypes={productTypes}
            onChange={products => setFilter(f => ({ ...f, productTypes: products }))}
          />
        }
      />

      <CustomAccordion
        title={localizedStrings.documentType}
        style={{ marginTop: '15px' }}
        children={
          <ProductTypeSelector
            selectorStyle={{ width: '100%' }}
            documentProductTypes={filter.documentTypes ? filter.documentTypes : []}
            productTypes={documentTypes}
            onChange={products => setFilter(f => ({ ...f, documentTypes: products }))}
          />
        }
      />

      <TextField
        id='filterDescriptionTextField'
        style={{ width: '100%', marginTop: 15 }}
        label={localizedStrings.description}
        size='small'
        value={filter.description ? filter.description : ''}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, description: e.target.value }))}
      />
    </>
  )
}
