import { CustomDataGrid, useWtxLocalization } from '@wavetronix/common-components'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

export default function GroupsDocumentsTable({ categoryMap, documents, isLoading, overridesComparator, renderSwitch }) {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen

  const localizedStrings = useWtxLocalization()
  const columns = [
    {
      field: 'override',
      headerName: 'Override',
      flex: 0.75,
      valueGetter: params => params.row.id,
      disableColumnMenu: true,
      sortComparator: overridesComparator,
      renderCell: data => renderSwitch(data.row)
    },
    {
      headerName: localizedStrings.name,
      field: 'fileName',
      flex: 2,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      headerName: localizedStrings.accessLevel,
      field: 'accessLevel',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen
    },
    {
      headerName: localizedStrings.associatedProducts,
      field: 'productTypes',
      flex: 2,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen,
      valueGetter: u => {
        let products = u.row.products
          ? u.row.products.map(pId => {
              if (categoryMap && categoryMap[pId]) {
                if (
                  categoryMap[pId].localization &&
                  categoryMap[pId].localization[window.navigator.language] &&
                  categoryMap[pId].localization[window.navigator.language] !== ''
                ) {
                  return categoryMap[pId].localization[window.navigator.language]
                } else {
                  return categoryMap[pId].name
                }
              } else {
                return pId
              }
            })
          : []
        return products.join(', ')
      }
    },
    {
      headerName: localizedStrings.documentType,
      field: 'docType',
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen,
      valueGetter: u => {
        let id = u.row.documentType
        let name = u.row.documentType
        if (categoryMap && categoryMap[id]) {
          if (
            categoryMap[id].localization &&
            categoryMap[id].localization[window.navigator.language] &&
            categoryMap[id].localization[window.navigator.language] !== ''
          ) {
            name = categoryMap[id].localization[window.navigator.language]
          } else {
            name = categoryMap[id].name
          }
        }
        return name
      }
    }
  ]

  const [pageSize, setPageSize] = useState(20)

  return (
    <div style={{ marginTop: '15px' }}>
      <Box sx={{ width: '100%' }}>
        <CustomDataGrid
          rows={documents}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={p => setPageSize(p.pageSize)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          disableSelectionOnClick
          loading={isLoading}
          disableExtendRowFullWidth={true}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: 'override',
                  sort: 'asc'
                }
              ]
            }
          }}
        />
      </Box>
    </div>
  )
}
