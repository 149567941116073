import axios from 'axios'
import { env } from '../index.js'
import { acquireAccessToken } from '@wavetronix/common-components'

let instance = null

class PackagesApi {
  getPackages = async (msalInstance, accounts) => {
    let token = await acquireAccessToken(msalInstance, accounts, env)
    return await axios
      .get(`${env.urls.documentsURL}/packages`, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 30000
      })
      .then(result => result.data)
  }
}
const getInstance = () => {
  if (instance == null) {
    instance = new PackagesApi()
  }
  return instance
}

export default getInstance()
